import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {ApiErrorResponse} from '../..';
import Api from './event.api';
import {
    CreateEventModel,
    EventModel,
    EventTransactionModel,
    GetEventTransactionModel,
    UpdateEventModel
} from './event.model';
import axios from "axios";
import {endpoint} from "../../constants";

export interface EventSlice {
    list?: EventModel[];
    single?: EventModel;
    transactionList?: EventTransactionModel[];
    isLoading?: boolean;
    error?: ApiErrorResponse<any>;
    status?: string;
    deleteStatus?: string;
    isExportLoading?: boolean;
}

export const createEvent = createAsyncThunk(
    'event/create',
    async (args: CreateEventModel, {rejectWithValue}) => {
        try {
            return await Api.createEvent(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getList = createAsyncThunk(
    'event/get-list',
    async (_: string | undefined = undefined, {rejectWithValue}) => {
        try {
            return await Api.getList();
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const getSingleItem = createAsyncThunk(
    'event/get-single-item',
    async (id: string, {rejectWithValue}) => {
        try {
            return await Api.getSingleItem(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const updateEvent = createAsyncThunk(
    'event/update',
    async (args: UpdateEventModel, {rejectWithValue}) => {
        try {
            return await Api.updateEvent(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const deleteEvent = createAsyncThunk(
    'event/delete',
    async (id: string, {rejectWithValue}) => {
        try {
            return await Api.deleteEvent(id);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
)

export const getListTransaction = createAsyncThunk(
    'event/get-transaction-list',
    async (args: GetEventTransactionModel, {rejectWithValue}) => {
        try {
            return await Api.getListTransaction(args);
        } catch (e) {
            return rejectWithValue(e as ApiErrorResponse<any>);
        }
    }
);

export const exportEventTransaction = createAsyncThunk('event/export-transaction', async (args: GetEventTransactionModel, {rejectWithValue}) => {
    try {
        const response = await axios({
            url: `${endpoint}/api/Event/EventExportToExcel`,
            params: args,
            method: 'post',
            responseType: 'blob',
        })
        return response.data
    }
    catch (error) {
        return rejectWithValue(error as ApiErrorResponse<any>);
    }
})

const eventSlice = createSlice({
    name: 'EventState',
    initialState: {} as EventSlice,
    reducers: {
        setFilter: (state, action) => {
            state.list = action.payload
        },
        reset: (state) => {
            state.single = {} as EventModel;
        },
        resetStatus: (state) => {
            state.status = "";
            state.deleteStatus = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createEvent.pending, (state) => {
            state.isLoading = true;
            state.status = "";
        });
        builder.addCase(createEvent.fulfilled, (state) => {
            state.isLoading = false;
            state.status = "success";
        });
        builder.addCase(createEvent.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
            state.status = "failed";
        });
        builder.addCase(getSingleItem.pending, (state) => {
            state.single = undefined;
            state.isLoading = true;

        });
        builder.addCase(getSingleItem.fulfilled, (state, {payload}) => {
            state.single = payload;
            state.isLoading = false;
        })
        builder.addCase(getSingleItem.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        })
        builder.addCase(getList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getList.fulfilled, (state, {payload}) => {
            state.list = payload;
            state.isLoading = false;
        });
        builder.addCase(getList.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(updateEvent.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updateEvent.fulfilled, (state) => {
            state.isLoading = false;
            state.status = "success";
        })
        builder.addCase(updateEvent.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.status = "failed";
            state.error = payload as ApiErrorResponse<any>;
        })
        builder.addCase(deleteEvent.pending, (state) => {
            state.isLoading = true;
            state.deleteStatus = "";
        })
        builder.addCase(deleteEvent.fulfilled, (state) => {
            state.isLoading = false;
            state.deleteStatus = "success";
        })
        builder.addCase(deleteEvent.rejected, (state, {payload}) => {
            state.isLoading = false;
            state.error = payload as ApiErrorResponse<any>;
            state.deleteStatus = "failed";
        })
        builder.addCase(getListTransaction.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getListTransaction.fulfilled, (state, {payload}) => {
            state.transactionList = payload;
            state.isLoading = false;
        });
        builder.addCase(getListTransaction.rejected, (state, {payload}) => {
            state.error = payload as ApiErrorResponse<any>;
            state.isLoading = false;
        });
        builder.addCase(exportEventTransaction.pending, (state, action) => {
            state.isExportLoading = true
        })
        builder.addCase(exportEventTransaction.fulfilled, (state, action) => {
            state.isExportLoading = false
        })
        builder.addCase(exportEventTransaction.rejected, (state, action) => {
            state.isExportLoading = false
        })
    },
})

export const {reset, resetStatus} = eventSlice.actions;
export default eventSlice.reducer;