import React, {useEffect, useState} from 'react';
import {Grid} from 'semantic-ui-react'
import {Card, Form, Row, Col, Button, InputGroup, FormControl} from 'react-bootstrap';
import {FaSearch} from "react-icons/fa";

import editIcon from '../../../assets/vector/edit.png';
import deleteIcon from '../../../assets/vector/delete.png';

import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {TableCustomStyles} from '../../../components/DataTable/TableCustomStyles';
import {RootState} from '../../../app/store';
import {deleteEvent, exportEventTransaction, getList, resetStatus} from '../event.reducer';
import {EventModel, EventTransactionModel} from '../event.model';
import DeleteMessage from "../../../components/DeleteMessage";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../constants";
import moment from "moment/moment";
import AlertMessage from "../../../components/alertMessage";
import {MembershipStatusEnum, MembershipTierTransaction} from "../../membersipTier/membershipTier.types";
import {postExportMembershipTransaction} from "../../membersip/membership.api";
import {unwrapResult} from "@reduxjs/toolkit";


const EventTransactionTable = () => {
    const dispatch = useDispatch();
    let {id} = useParams<{ id: string }>();

    const eventTransactionList = useSelector(
        (state: RootState) => state.event.transactionList ?? []
    );
    const event = useSelector(
        (state: RootState) => state.event.single ?? {} as EventModel
    );

    let deleteStatus = useSelector((state: RootState) => state.event.deleteStatus ?? "");
    let isExportLoading = useSelector((state: RootState) => state.event.isExportLoading ?? false);

    // datatable components
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle] = useState(false);
    const [selectedId, setSelectedId] = useState("");


    //alert
    const [showAlert, setShowAlert] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({
        variant: "",
        title: "",
        content: "",
    });


    const filteredItems = eventTransactionList.filter(
        (item) =>
            item.partnerName &&
            item.partnerName.toLowerCase().includes(filterText.toLowerCase())
    );

    const onAlertClose = () => {
        setShowAlert(false);
    };

    const columns = [
        {
            name: "NAMA PARTNER",
            selector: "partnerName",
            sortable: true,
        },
        {
            name: "TANGGAL REDEEM",
            selector: (d: EventTransactionModel) => (
                <>
                    <p style={{paddingTop: 15}}>
                        {d?.date === null ? "-" : moment(d?.date).format('DD/MM/YYYY HH:mm')}
                    </p>
                </>
            ),
            sortable: true,
        },
        {
            name: "KODE",
            selector: "code",
            sortable: true,
        },
    ];

    useEffect(() => {
        dispatch(getList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDeleteData = () => {
        if (selectedId !== undefined) {
            dispatch(deleteEvent(selectedId));
            setShowAlert(false);
        }
    };

    useEffect(() => {
        if (deleteStatus === "success") {
            setAlertProps({
                variant: "success",
                title: "Berhasil menghapus event",
                content: "",
            });
            setShowDeleteAlert(true);
            dispatch(resetStatus());
            dispatch(getList());
        } else if (deleteStatus === "failed") {
            setAlertProps({
                variant: "failed",
                title: "Gagal",
                content: "Terjadi kesalahan saat menghapus data",
            });
            setShowDeleteAlert(true);
            dispatch(resetStatus());
            dispatch(getList());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteStatus]);

    const onExportClicked = () => {
        dispatch<any>(exportEventTransaction({
            eventId: id,
        }))
            .then(unwrapResult)
            .then((res: any) => {
                console.log(res)
                const url = URL.createObjectURL(new Blob([res]));
                console.log(url)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `kupon-${event.name}.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err: any) => console.log(err))
    }


    return (
        <div>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label className="page-title" style={{textTransform: 'uppercase'}}>KUPON REDEEM {event !== null ? ` - ${event.name}` : ''}</Form.Label>
                    </Form.Group>
                </Col>
                <Col>
                    <Button className="float-right" variant="info" onClick={onExportClicked} disabled={isExportLoading}>Export</Button>
                </Col>
            </Row>

            <Grid textAlign='center' style={{height: '100vh'}}>
                <Card>
                    <Row>
                        <Col md={4}>
                            <InputGroup className="mb-3 padding-filter" style={{width: 330}}>
                                <InputGroup.Prepend className="search-input">
                                    <InputGroup.Text style={{
                                        backgroundColor: '#fff',
                                        color: '#F5B95D',
                                        border: 0
                                    }}><FaSearch/></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    style={{borderLeft: 0}}
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setFilterText(e.target.value)
                                    }}
                                    value={filterText}
                                />
                            </InputGroup>
                        </Col>
                        {/* <Col md={5}>
              <Form.Group as={Row} className="padding-filter mb-3 ">
                <Form.Label column sm={3} style={{fontStyle: "normal", fontWeight: "normal", fontSize: 14}}>
                  User level
                </Form.Label>
                <Col sm={6}>
                  <Form.Control as="select" style={{height: 35}}>
                    <option>select</option>
                  </Form.Control>
                </Col>
                <Col sm={2}>
                    <Button style={{width: 69, height: 35, fontSize: 14 }}  variant="outline-info">Reset</Button>
                </Col>
              </Form.Group>
            </Col> */}
                    </Row>

                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        noHeader
                        customStyles={TableCustomStyles}
                        paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                    />
                </Card>
            </Grid>

            <DeleteMessage
                {...alertProps}
                show={showAlert}
                onAlertCloseAction={onAlertClose}
                onDeleteAction={onDeleteData}
            ></DeleteMessage>

            <AlertMessage
                {...alertProps}
                show={showDeleteAlert}
                onAlertCloseAction={() => setShowDeleteAlert(false)}
            ></AlertMessage>
        </div>
    )
};

export default EventTransactionTable;
