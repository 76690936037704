import React, {ChangeEvent, useEffect, useState} from 'react'
import '../membership.scss'
import {Container, Row, Col, Form, Card, Modal, Button} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert2';

import {RootState} from '../../../app/store';
import moment from 'moment';
import {confirmPayment, getMembershipTierTransaction, rejectPayment} from "../membershipTier.api";
import DataTable from "react-data-table-component";
import {endpoint, MAX_PAGING_OPTIONS} from "../../../constants";
import {Grid} from "semantic-ui-react";
import {MembershipStatusEnum, MembershipTierTransaction} from "../membershipTier.types";
import {resetStatus} from "../membershipTier.reducer";


const MembershipTier = () => {
    // const history = useHistory()
    const dispatch = useDispatch()

    const {isGetMembershipLoading, confirmStatus, rejectStatus} = useSelector((state: RootState) => state.membershipTier)

    const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
    const [showModal, setShowModal] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10);

    const [rejectionNote, setRejectionNote] = useState<string>("");

    const userState = useSelector((state: RootState) => state.user ?? {});

    const membershipTierList = useSelector(
        (state: RootState) => state.membershipTier.memberships ?? []
    );

    const membershipTierTotal = useSelector(
        (state: RootState) => state.membershipTier.total ?? 0
    );

    useEffect(() => {
        fetchData(0, perPage);
    }, []);

    useEffect(() => {
        if (confirmStatus !== 'pending') {
            if (confirmStatus === 'success') {
                Swal.fire(
                    'Berhasil!',
                    'Pembayaran berhasil dikonfirmasi.',
                    'success'
                )
            } else if (confirmStatus === 'failed') {
                Swal.fire(
                    'Error!',
                    'Pembayaran gagal dikonfirmasi.',
                    'error'
                )
            }
            dispatch(resetStatus());
            fetchData(0, perPage);
        }
    }, [confirmStatus]);

    useEffect(() => {
        if (rejectStatus !== 'pending') {
            if (rejectStatus === 'success') {
                Swal.fire(
                    'Berhasil!',
                    'Pembayaran berhasil dibatalkan.',
                    'success'
                )
            } else if (rejectStatus === 'failed') {
                Swal.fire(
                    'Error!',
                    'Pembayaran gagal dibatalkan.',
                    'error'
                )
            }
            dispatch(resetStatus());
            fetchData(0, perPage);
            handleClose();
            setRejectionNote("");
        }
    }, [rejectStatus]);

    const handleChangeStatus = (id: string, image: string) => {
        Swal.fire({
            title: 'apakah anda yakin ingin mengkonfirmasi pembayaran?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#f5b95d',
            // cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result: any) => {
            if (result.isConfirmed) {
                dispatch(
                    confirmPayment({
                        id: id,
                        confirmationPaymentDate: new Date().toISOString(),
                        image: image,
                    })
                );
            }
        })
    };

    const handleCancelStatus = (id: string, image: string) => {
        setSelectedId(id);
        setShowModal(true);

    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedId(undefined);
    };

    const onRejectClick = () => {
        if (selectedId !== undefined) {
            dispatch(
                rejectPayment({
                    id: selectedId,
                    note: rejectionNote,
                })
            );
        }

    }

    const onRejectionNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRejectionNote(e.target.value);
    };

    const columns: any = [
        {
            name: "CUSTOMER",
            selector: "partnerName",
            sortable: false,
        },
        {
            name: "TIPE MEMBERSHIP",
            selector: "membershipTierTitle",
            sortable: false,
        },
        {
            name: "STATUS",
            selector: (d: { status: number }) => (
                <>
                    <p style={{paddingTop: 15}}>
                        {d.status === MembershipStatusEnum.WaitingForPayment && "Menunggu Pembayaran"}
                        {d.status === MembershipStatusEnum.WaitingForConfirmation && "Menunggu Konfirmasi"}
                        {d.status === MembershipStatusEnum.Approved && "Diterima"}
                        {d.status === MembershipStatusEnum.Reject && "Ditolak"}
                        {d.status === MembershipStatusEnum.Expired && "Kadaluarsa"}
                    </p>
                </>
            ),
            sortable: true,
        },
        {
            name: "ATTACHMENT",
            selector: (d: MembershipTierTransaction) => (
                <>
                    {
                        d.status !== MembershipStatusEnum.WaitingForPayment && (
                            <button
                                style={{
                                    margin: 3,
                                    width: 70,
                                    height: 23,
                                    background: "#2ECC71",
                                    borderRadius: 5,
                                    color: "white",
                                }}
                                onClick={() => {
                                    window.open(`${endpoint}/${d.image}`, "_blank");
                                }}
                            >
                                View
                            </button>
                        )
                    }
                </>
            ),
        },
        {
            name: "TANGGAL KONFIRMASI",
            selector: (d: MembershipTierTransaction) => (
                <>
                    <p style={{paddingTop: 15}}>
                        {d?.confirmationPaymentDate === null ? "-" : moment(d?.confirmationPaymentDate).format('DD/MM/YYYY HH:mm')}
                    </p>
                </>
            ),
            sortable: true,
        },
        {
            name: "ALASAN PENOLAKAN",
            selector: "note",
            sortable: false,
        },
        {
            name: "ACTION",
            selector: (d: MembershipTierTransaction) => (
                <>
                    {d.status === MembershipStatusEnum.WaitingForConfirmation && (userState.role?.includes("Finance") || userState.role?.includes("SUPER")) ? (
                        <>
                            <button
                                style={{
                                    margin: 3,
                                    width: 70,
                                    height: 23,
                                    background: "#F52B3D",
                                    borderRadius: 5,
                                    color: "white",
                                }}
                                disabled={d.status !== MembershipStatusEnum.WaitingForConfirmation}
                                onClick={() => {
                                    handleCancelStatus(d.id, d.image);
                                }}
                            >
                                Reject
                            </button>
                            <br />
                            <button
                                style={{
                                    margin: 3,
                                    width: 70,
                                    height: 23,
                                    background: "#2ECC71",
                                    borderRadius: 5,
                                    color: "white",
                                }}
                                disabled={d.status !== MembershipStatusEnum.WaitingForConfirmation}
                                onClick={() => {
                                    handleChangeStatus(d.id, d.image);
                                }}
                            >
                                Approve
                            </button>
                        </>
                    ) : (
                        <> </>
                    )}
                </>
            ),
        },
    ];

    const fetchData = (page: number, perPage: number) => {
        dispatch(
            getMembershipTierTransaction({
                skip: page,
                take: perPage,
            })
        );
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        fetchData(page, newPerPage);
    };

    const handlePageChange = (page: any) => {
        fetchData((page - 1) * perPage, perPage);
    };

    return (
        <Container className="content-body" fluid>
            <div>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label column={"sm"} className="page-title">Membership</Form.Label>
                        </Form.Group>
                    </Col>
                </Row>

                <Grid textAlign="center" style={{height: "100vh"}}>
                    <Card>
                        <DataTable
                            columns={columns}
                            data={membershipTierList}
                            pagination
                            paginationServer
                            progressPending={isGetMembershipLoading}
                            paginationResetDefaultPage={false}
                            noHeader
                            paginationTotalRows={membershipTierTotal}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationRowsPerPageOptions={MAX_PAGING_OPTIONS}
                        />
                    </Card>
                </Grid>
            </div>

            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showModal}
                onHide={handleClose}>
                <Modal.Header closeButton>
                    <div style={{ fontWeight: "bold" }}>Reject Data</div>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Apakah kamu yakin akan membatalkan pembayaran ini?</Form.Label>

                    <Form.Group className="input-padding">
                        <Form.Label>Alasan</Form.Label>

                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Note"
                            onChange={onRejectionNoteChange}
                            value={rejectionNote}
                        />
                    </Form.Group>

                    <div className="btn-footer-group float-right">
                        <Button
                            variant="info"
                            style={{ width: 130, height: 45 }}
                            onClick={onRejectClick}>
                            Save
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    )
}

export default MembershipTier;

