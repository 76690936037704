import { createSlice } from '@reduxjs/toolkit'
import {
    getFloatingBannerList, getSingleFloatingBanner, updateFloatingBanner,
    uploadImage,
} from './floatingBanner.api'
import {FloatingBannerModel} from "./model";

export interface FloatingBannerSlice {
  loading : boolean,
  floatingBannerList : FloatingBannerModel[],
  updateStatus: 'pending' | 'success' | 'failed',
}
const initialState: FloatingBannerSlice = {
  loading : false,
  floatingBannerList : [],
    updateStatus: 'pending',
}

export const floatingBannerSlice = createSlice({
    name: 'floatingBannerState',
    initialState,
    reducers: {
        // increment: (state) => {
        // state.value += 1
    },
    extraReducers : (builder) => {
        // Get
        builder.addCase(getFloatingBannerList.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(getFloatingBannerList.fulfilled, (state, action) => {
            state.loading = false
            state.floatingBannerList = action.payload.data
        })
        builder.addCase(getFloatingBannerList.rejected, (state, action) => {
            state.loading = false
        })
        //post
        builder.addCase(uploadImage.pending, (state, action) => {
          state.loading = true
        })
        builder.addCase(uploadImage.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(uploadImage.rejected, (state, action) => {
            state.loading = false
        })
        //detail
        builder.addCase(getSingleFloatingBanner.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSingleFloatingBanner.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(getSingleFloatingBanner.rejected, (state, action) => {
            state.loading = false
        })
        //update
        builder.addCase(updateFloatingBanner.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(updateFloatingBanner.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(updateFloatingBanner.rejected, (state, action) => {
            state.loading = false
        })
    },
})

export default floatingBannerSlice.reducer