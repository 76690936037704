import { StringLiteral } from "typescript";

export interface ProductModel {
  id: string;
  productCategoryList: string[];
  membershipTierList: string;
  productCollectionTagList: string[];
  createdDate: string;
  productAttributeList: string[];
  productAttributeWithDetail: AttributeListWithDetailModel[];
  productGroupingList: string[];
  productType: number;
  warehouseId: string;
  name: string;
  code: string;
  insertMedia: string;
  visualProduct: string;
  visualProductReseller: string;
  textProduct: string;
  setProductImageId: string;
  productGaleryId: string;
  isEnableSizingGuide: boolean;
  sizingGuideText: string;
  sizingGuideContentVisual: string;
  sizingGuideContentText: string;
  sizingGuideContentAddMedia: string;
  productDataType: number;
  isVirtualSimpleProduct: boolean;
  isDownloadableSimpleProduct: boolean;
  generalRegulerPriceStockist: number;
  generalSalePriceStockist: number;
  generalRegulerPriceReseller: number;
  generalRegulerPriceEndCust: number;
  generalSharePrice: number;
  generalSalesPriceEndCust: number;
  generalCouponStockist: number;
  generalCouponReseller: number;
  generalCouponEndCust: number;
  generalProductURI: string;
  generalButtonText: string;
  inventorySKU: string;
  isManageStockInventory: boolean;
  stockStatusInventory: number;
  isSoldindivitualyInventory: boolean;
  shippingWeight: number;
  shippingDimensionsLength: number;
  shippingDimensionsWidth: number;
  shippingDimensionsHeight: number;
  shippingClass: number;
  advancedPurchaseNote: string;
  advancedMenuOrder: number;
  isEnableReviewAdvanced: boolean;
  isVisibleOnProductPage: boolean;
  isUsedForVariations: boolean;
  productCategoryName: string[];
  uom: number;
  adminId? : string;
  isPublishReseller	:	boolean;
isPublishEndCustomer	:	boolean;
isPublishStockist	:	boolean;
isActive: boolean;
  rating: number;
  isHighlight: boolean;
}

export interface AttributeListWithDetailModel{
  productAttributeId: string;
  productAttributeName: string;
  productConfiguration: AttributeDetailModel[];
}

export interface AttributeDetailModel{
  id: string;
  name: string;
}

export interface FilterModel {
  name: string;
  inventorySKU: string;
  productType: number|null;
  startDate: string;
  endDate: string;
  membershipTierId: string | null;
  isActive: boolean;
  isHighlight: boolean | null;
  skip: number;
  take: number;
}

export interface GroupingModel {
  id: string;
  name: string;
}

export interface CollectionTagModel {
  id: string;
  name: string;
  banner: string;
  description: string;
  keyword: string;
  isActive: boolean;
}

export interface ProductCategoryModel {
  key: string;
  name: string;
  slug: string;
  code: string;
  description: string;
  children: ProductCategoryModel[];
  image: string;
  isActive: boolean;
}

// export interface ProductPublishModel {
//   status: number;
//   visibility: number;
//   publishDatePartner: Date;
//   publishDateCustomer: Date;
//   resellerDate: Date;
//   stockistDate: Date;
//   isFeaturedProduct: boolean;
//   productId: string;
//   isActive: boolean;
// }

export interface ProductPublishModel {
  isPublishImmediatelyEndCustomer: boolean,
  isPublishImmediatelyReseller: boolean,
  isPublishImmediatelyStockist: boolean,
  productId: string,
  endCustomerDate:Date,
  resellerDate: Date,
  stockistDate: Date,
  isActive: boolean
}

export interface AttributeModel {
  id: string;
  name: string;
  slug: string;
  isEnableArchive: boolean;
  type: number;
  defaultShortOrder: number;
  isActive: boolean;
}

export interface CreateProductModel {
  productModelData: ProductModel;
  productPublishData: ProductPublishModel;
  linkCrossSellData: ProductCrossSellModel;
  linkUpSellData: ProductUpSellModel;
  linkGroupingData: ProductGroupingModel;
  imageGalleryData: ImageGalleryModel;
  variationData: variationModel[];
}

export interface UploadImageModel {
  file_name: string;
  base64url: string;
  imagepath: string;
  location: string;
}

export interface WarehouseModel {
  id: string;
  code: string;
  name: string;
  phoneNumber: string;
  address: string;
  postalCode?: string;
  note: string;
  allowNegativeStock?: boolean;
  fullfillmentOnlineOrder?: boolean;
  fullfillmentPriority?: number;
  countryId?: string;
  provinceId: string;
  cityId: string;
  districtId: string;
  city?: string;
  isActive: boolean;
}

export interface ProductCrossSellModel {
  productId: string;
  productCrossSell: string;
}

export interface ProductUpSellModel {
  productId: string;
  productUpSells: string;
}

export interface ProductGroupingModel {
  productId: string;
  productGrouping: string;
}

export interface LinkProductModel {
  productCrossSell: string;
  productUpSells: string;
  productGrouping: string;
}

export interface ImageGalleryModel {
  productId: string;
  path: string[];
}

export interface inputConfigureTermsModel {
  [key: string]: any;
  isVisible?: boolean;
  isForVariation?: boolean;
  arrayOfConfigureTermsId?: string[];
}

export interface variationModel {
  id? : any;
  // customProductAttributeList: string[]
  customProductAttributeList: CustomProductAttibuteModel[];
  configurationAttributeName1: string;
  configurationAttributeName2: string;
  configurationAttributeName3: string;
  productId: string;
  sku: string;
  name: string;
  isEnable: boolean;
  isDownloadable: boolean;
  isVirtual: boolean;
  isManageStock: boolean;
  // regulerPrice: number;
  // salePrice: number;

  regulerPriceEndCustomer: number;
  salePriceEndCustomer: number;
  regulerPriceReseller: number;
  salePriceReseller: number;
  regulerPriceStockist: number;
  salePriceStockist: number;

  sharePriceReseller: number;
  sharePriceStockist: number;

  startSaleCustomer: string;
  endSaleCustomer: string;
  startSaleReseller: string;
  endSaleReseller: string;
  startSaleStockist: string;
  endSaleStockist: string;

  stockQty: number;
  stockStatus: number;
  allowBackOrder: number;
  shippingClass: number;
  weight: number;
  dimensionsLength: number;
  dimensionsWidth: number;
  dimensionsHeight: number;
  variationDescription: string;
  image: string;
  index: number;
  [key: string]: any;
  iconColor:string;
  warehouseStockList : VariationWarehouseStock[];
  maxResellerQty: number;
  maxStockistQty: number;
  isActive? : boolean;
  
}

export interface CustomProductAttibuteModel{
  id: string;
  name: string;
}

//generate Custom Product Attribute
export interface ListOfCustomProductAttribute {
  configurationAttributeId: string[];
}

export interface VariationWarehouseStock {
  warehouseId : string;
  warehouseName : string;
  quantity: number;
  quantitySystem: number;
}

export interface ConfigureTermModel {
  id: string;
  name: string;
  slug: string;
  parent: string;
  description: string;
  productAttributeId: string;
}

export enum VariantUpdateIndexTypeEnum
{
  Up, Down
}

export interface UpdateIndexModel {
  variantId: string;
  type: VariantUpdateIndexTypeEnum;
}