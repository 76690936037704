import { ChangeEvent, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { Card, Form, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import AlertMessage from "../../../../components/alertMessage";
import DeleteMessage from "../../../../components/DeleteMessage";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../../../../components/rich-text/editorToolbar";
import "react-quill/dist/quill.snow.css";
import "../product.css";
import PublishForm from "./publishForm";
import ProductionCategoriesForm from "./productionCategories";
import ProductionTagsForm from "./productTagForm";
import { Switch } from "antd";
import GeneralProduct from "./ProductDataTab/generalProduct";
import InventoryProduct from "./ProductDataTab/inventoryProduct";
import ShippingProduct from "./ProductDataTab/shippingProduct";
import LinkProduct from "./ProductDataTab/linkProduct";
import {
  createData,
  getWarehouseList,
  resetImageGallery,
  resetSingleProduct,
  resetStatus,
  setIsVariationDispatch,
  updateData,
} from "../product.reducer";
import {
  CreateProductModel,
  LinkProductModel,
  ProductModel,
  ProductPublishModel,
} from "../product.model";
import AdvanceProduct from "./ProductDataTab/advancedProduct";
import AttributeSimpleProductForm from "./ProductDataTab/attributeProduct";
import ProductImageGallery from "./productImageGallery";
import AttributeVariableProductForm from "./variableProductTab/attributeVariableProductForm";
import VariationVariableProductForm from "./variableProductTab/variationsVariableProductForm";

import MDEditor from "@uiw/react-md-editor";
import ProductMembership from "./productMembership";


const ProductForm = ({ data = {} as ProductModel, isEdit = false, ...props }: ProductFormProps) => {
  let { id } = useParams<{ id: string }>();

  let history = useHistory();

  const dispatch = useDispatch();

  const SimpleProductGeneralState = useSelector(
    (state: RootState) => state.product.setSimpleProductGeneralTempForm ?? ({} as ProductModel)
  );

  const SimpleProductInventoryState = useSelector(
    (state: RootState) => state.product.setSimpleProductInventoryTempForm ?? ({} as ProductModel)
  );

  const SimpleProductShippingState = useSelector(
    (state: RootState) => state.product.setSimpleProductShippingTempForm ?? ({} as ProductModel)
  );

  const SimpleProductAdvancedState = useSelector(
    (state: RootState) => state.product.setSimpleProductAdvancedTempForm ?? ({} as ProductModel)
  );

  const SimpleProductLinkState = useSelector(
    (state: RootState) => state.product.setLinkProductTempForm ?? ({} as LinkProductModel)
  );

  const GroupingListPerProductState = useSelector(
    (state:RootState) => state.product.listGroupingEditData ?? []
  );

  const SimpleProductAttributedState = useSelector(
    (state: RootState) => state.product.setSimpleProductAttributeTempForm ?? []
  );

  const VariableProductAttributeState = useSelector(
    (state: RootState) => state.product.setVariableProductAttributeTempForm ?? []
  )

  const publishState = useSelector(
    (state: RootState) => state.product.setPublishTempForm ?? ({} as ProductPublishModel)
  );

  const warehouseState = useSelector((state: RootState) => state.product.listWarehouse ?? []);

  const collectionTagState = useSelector(
    (state: RootState) => state.product.setProductTagsTempForm ?? []
  );

  const productCategoriesState = useSelector(
    (state: RootState) => state.product.setProductCategoriesTempForm ?? []
  );

  const productMembershipState = useSelector(
      (state: RootState) => state.product.setProductMembershipTempForm ?? []
  );

  const imageGalleryState = useSelector(
    (state: RootState) => state.product.setImageGalleryTempForm ?? []
  );

  const variationState = useSelector(
    (state: RootState) => state.product.setVariableProductVariationTempForm ?? []
  );

  const GeneratedAttributeState = useSelector(
    (state : RootState) => state.product.isAttributeGenerated ?? false
  );

  const VariationDispatchState = useSelector (
    (state : RootState) => state.product.isVariationDispatched ?? false
  )

  const userIdDataState = useSelector(
    (state: RootState) => state.user.id  ?? ""
  )

  const createStatus = useSelector((state: RootState) => state.product.status ?? "");

  const updateVariantStatus = useSelector((state: RootState) => state.product.updateVariantStatus ?? "");

  const errorMessage = useSelector((state: RootState) => state.product.error ?? "");

  const variantErrorMessage = useSelector((state: RootState) => state.product.variantError);

  useEffect(() => {
    dispatch(getWarehouseList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [warehouseId, setWarehouseId] = useState("");
  const [productType, setProductType] = useState(0);
  const [name, setProductName] = useState("");
  const [sku, setSku] = useState("");
  const [rating, setRating] = useState(0);
  const [visualProduct, setVisualProduct] = useState("");
  const [visualProductReseller, setVisualProductReseller] = useState("");
  const [textProduct, setTextProduct] = useState("");
  const [isVisibleOnProductPage, setIsVisibleOnProductPage] = useState(true);
  const [isEnableSizingGuide, setIsEnableSizingGuide] = useState(true);
  const [sizingGuideText, setSizingGuideText] = useState("");
  const [sizingGuideContentVisual, setSizingGuideContentVisual] = useState("");
  const [sizingGuideContentText, setSizingGuideContentText] = useState("");
  const [productDataType, setProductDataType] = useState(3);
  const [isVirtualSimpleProduct, setIsVirtualSimpleProduct] = useState(true);
  const [isDownloadableSimpleProduct, setIsDownloadableSimpleProduct] = useState(true);
  const [isHighlight, setIsHighlight] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [confirmAlertProps, setConfirmAlertProps] = useState({
    variant: "",
    title: "",
    content: "",
  });

  const onProduxtTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductType(parseInt(e.target.value));
  };

  const onProductNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductName(e.target.value);
  };

  const onProductSKUChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSku(e.target.value);
  };

  const onProductRatingChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRating(parseFloat(e.target.value) > 5 ? 5 : parseFloat(e.target.value));
  };

  const onVisualProductChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setVisualProduct(e.target.value);
  };

  const onSizingGuideTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSizingGuideText(e.target.value);
  };

  const onProductDataTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductDataType(parseInt(e.target.value));
  };

  const onWarehouseIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWarehouseId(e.target.value);
  };

  useEffect(() => {
    if (id === undefined) {
      dispatch(resetSingleProduct());
      dispatch(resetImageGallery());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
      // setWarehouseId(data.warehouseId);
      console.log(data)
      setProductType(data.productType);
      setProductName(data.name);
      setVisualProduct(data.visualProduct);
      setVisualProductReseller(data.visualProductReseller);
      setTextProduct(data.textProduct);
      setIsVisibleOnProductPage(data.isVisibleOnProductPage);
      setIsEnableSizingGuide(data.isEnableSizingGuide);
      setSizingGuideText(data.sizingGuideText);
      setSizingGuideContentVisual(data.sizingGuideContentVisual);
      setSizingGuideContentText(data.sizingGuideContentText);
      setProductDataType(data.productDataType);
      setIsVirtualSimpleProduct(data.isVirtualSimpleProduct);
      setIsDownloadableSimpleProduct(data.isDownloadableSimpleProduct);
      setSku(data.inventorySKU);
      setRating(data.rating);
      setIsHighlight(data.isHighlight);
    } else {
      // setWarehouseId("");
      setProductType(0);
      setProductName("");
      setVisualProduct("");
      setVisualProductReseller("");
      setTextProduct("");
      setIsVisibleOnProductPage(true);
      setIsEnableSizingGuide(true);
      setSizingGuideText("");
      setSizingGuideContentVisual("");
      setSizingGuideContentText("");
      setSku("");
      setProductDataType(0);
      setIsVirtualSimpleProduct(true);
      setIsDownloadableSimpleProduct(true);
      setRating(0);
      setIsHighlight(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (createStatus === "success") {
      if (isEdit) {
        if (updateVariantStatus === "success") {
          setAlertProps({
            variant: "success",
            title: "Berhasil Menyimpan Data Produk",
            content: "",
          });
          setShowAlert(true);
        } else if (updateVariantStatus === "failed") {
          setAlertProps({
            variant: "failed",
            title: "Gagal",
            content: variantErrorMessage?.data?.message ?? "Terjadi kesalahan ketika menyimpan varian",
          });
          setShowAlert(true);
        }

      } else {
        setAlertProps({
          variant: "success",
          title: "Berhasil Menyimpan Data Produk",
          content: "",
        });
        setShowAlert(true);
      }

    } else if (createStatus === "failed") {
      let arr = JSON.stringify(errorMessage);
      let parse = JSON.parse(arr);
      let message = parse.data;
      let xhr = "";

      if (message.message === "error") {
        xhr = message.data[0].description;
      } else {
        xhr = message.title;
      }
      setAlertProps({
        variant: "failed",
        title: "Gagal",
        content: xhr,
      });
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus, updateVariantStatus, variantErrorMessage]);

  const onSwitchSizingGuideChange = () => {
    setIsEnableSizingGuide(!isEnableSizingGuide);
  };

  const onSubmitForm = () => {

    if (isEdit) {

      // if (!VariationDispatchState){
      //   setAlertProps({
      //     variant: "Info",
      //     title: "",
      //     content: "Data Varian Belum disimpan. Tekan SAVE kembali untuk melanjutkan.",
      //   });
      //   dispatch(setIsVariationDispatch(true))
      //   setShowAlert(true);
      // } else {

        console.log(variationState)

        dispatch(
          updateData({
            productModelData: {
              id: data.id,
              productCategoryList: productCategoriesState,
              membershipTierList: productMembershipState?.toString(),
              productCollectionTagList: collectionTagState,
              productAttributeList: VariableProductAttributeState,
              productGroupingList: GroupingListPerProductState,
              warehouseId,
              productType,
              name,
              visualProduct,
              visualProductReseller,
              textProduct,
              isVisibleOnProductPage,
              isEnableSizingGuide,
              sizingGuideText,
              sizingGuideContentVisual,
              sizingGuideContentText,
              productDataType,
              isVirtualSimpleProduct,
              isDownloadableSimpleProduct,
              generalRegulerPriceReseller: SimpleProductGeneralState.generalRegulerPriceReseller,
              generalSharePrice: SimpleProductGeneralState.generalSharePrice,
              generalRegulerPriceEndCust: SimpleProductGeneralState.generalRegulerPriceEndCust,
              rating,
              isHighlight,

              inventorySKU: sku,
              isManageStockInventory: SimpleProductInventoryState.isManageStockInventory,
              stockStatusInventory: SimpleProductInventoryState.stockStatusInventory,

              shippingWeight: SimpleProductShippingState.shippingWeight,
              shippingDimensionsLength: SimpleProductShippingState.shippingDimensionsLength,
              shippingDimensionsWidth: SimpleProductShippingState.shippingDimensionsWidth,
              shippingDimensionsHeight: SimpleProductShippingState.shippingDimensionsHeight,
              shippingClass: SimpleProductShippingState.shippingClass,

              advancedPurchaseNote: SimpleProductAdvancedState.advancedPurchaseNote,
              advancedMenuOrder: SimpleProductAdvancedState.advancedMenuOrder,
              isEnableReviewAdvanced: SimpleProductAdvancedState.isEnableReviewAdvanced,
              uom: SimpleProductInventoryState.uom,
              adminId : userIdDataState,
            },
            productPublishData: {
              isPublishImmediatelyEndCustomer: publishState.isPublishImmediatelyEndCustomer,
              isPublishImmediatelyReseller: publishState.isPublishImmediatelyReseller,
              isPublishImmediatelyStockist: publishState.isPublishImmediatelyStockist,
              productId: publishState.productId,
              endCustomerDate:publishState.endCustomerDate,
              resellerDate: publishState.resellerDate,
              stockistDate: publishState.stockistDate,
              isActive: publishState.isActive
            },
            linkCrossSellData: {
              productCrossSell: SimpleProductLinkState.productCrossSell,
            },
            linkUpSellData: {
              productUpSells: SimpleProductLinkState.productCrossSell,
            },
            linkGroupingData: {
              productGrouping: SimpleProductLinkState.productGrouping,
            },
            imageGalleryData: {
              path: imageGalleryState,
            },
            variationData: variationState,

          } as CreateProductModel)
        );

      // }


    } else {

      // if(!GeneratedAttributeState){
      //   setAlertProps({
      //     variant: "Info",
      //     title: "Simpan Data Attribut terlebih dahulu.",
      //     content: "",
      //   });
      //   setShowAlert(true);
      // }else if(!VariationDispatchState){
      //   setAlertProps({
      //     variant: "Info",
      //     title: "Simpan Data Varian terlebih dahulu.",
      //     content: "",
      //   });
      //   setShowAlert(true);
      // } else {
        dispatch(
          createData({
            productModelData: {
              productCategoryList: productCategoriesState,
              membershipTierList: productMembershipState?.toString(),
              productCollectionTagList: collectionTagState,
              productAttributeList: VariableProductAttributeState,
              productGroupingList: GroupingListPerProductState,
              warehouseId,
              productType,
              name,
              visualProduct,
              visualProductReseller,
              textProduct,
              isVisibleOnProductPage,
              isEnableSizingGuide,
              sizingGuideText,
              sizingGuideContentVisual,
              sizingGuideContentText,
              productDataType,
              isVirtualSimpleProduct,
              isDownloadableSimpleProduct,
              generalRegulerPriceReseller: SimpleProductGeneralState.generalRegulerPriceReseller,
              generalSharePrice: SimpleProductGeneralState.generalSharePrice,
              generalRegulerPriceEndCust: SimpleProductGeneralState.generalRegulerPriceEndCust,
              rating,
              isHighlight,

              // inventorySKU: SimpleProductInventoryState.inventorySKU,
              inventorySKU: sku,
              isManageStockInventory: SimpleProductInventoryState.isManageStockInventory,
              stockStatusInventory: SimpleProductInventoryState.stockStatusInventory,

              shippingWeight: SimpleProductShippingState.shippingWeight,
              shippingDimensionsLength: SimpleProductShippingState.shippingDimensionsLength,
              shippingDimensionsWidth: SimpleProductShippingState.shippingDimensionsWidth,
              shippingDimensionsHeight: SimpleProductShippingState.shippingDimensionsHeight,
              shippingClass: SimpleProductShippingState.shippingClass,

              advancedPurchaseNote: SimpleProductAdvancedState.advancedPurchaseNote,
              advancedMenuOrder: SimpleProductAdvancedState.advancedMenuOrder,
              isEnableReviewAdvanced: SimpleProductAdvancedState.isEnableReviewAdvanced,
              uom: SimpleProductInventoryState.uom,
              adminId : userIdDataState,
            },
            productPublishData: {
              isPublishImmediatelyEndCustomer: publishState.isPublishImmediatelyEndCustomer,
              isPublishImmediatelyReseller: publishState.isPublishImmediatelyReseller,
              isPublishImmediatelyStockist: publishState.isPublishImmediatelyStockist,
              productId: publishState.productId,
              endCustomerDate:publishState.endCustomerDate,
              resellerDate: publishState.resellerDate,
              stockistDate: publishState.stockistDate,
              isActive: publishState.isActive
            },
            linkCrossSellData: {
              productCrossSell: SimpleProductLinkState.productCrossSell,
            },
            linkUpSellData: {
              productUpSells: SimpleProductLinkState.productCrossSell,
            },
            linkGroupingData: {
              productGrouping: SimpleProductLinkState.productGrouping,
            },
            imageGalleryData: {
              path: imageGalleryState,
            },
            variationData: variationState,
          } as CreateProductModel)
        );
      }
    // }
  };

  const onAlertClose = () => {
    setShowAlert(false);
    if (createStatus === "success") {
      dispatch(resetStatus());
      dispatch(resetSingleProduct());
      dispatch(resetImageGallery());
      history.replace("/product/index");
    } else {
      dispatch(resetStatus());
    }
  };

  const onConfirmAlertClose = () => {
    setShowConfirmAlert(false)
  }

  const onConfirm = () => {
    if(!isEdit){
        dispatch(setIsVariationDispatch(true))
    }
  }

  const onContentChange = (e:any) => {
    setVisualProduct(e.toString());
  }

  const onVisualResellerChange = (e:any) => {
    setVisualProductReseller(e.toString());
  }

  // const onCheckCategory = (item: string) => {
  //   let newArr = [];
  //   if (!warehouseId.includes(item)) {
  //     newArr = [...warehouseId, item];
  //   } else {
  //     newArr = warehouseId.filter((a) => a !== item);
  //   }
  //   setWarehouseId(newArr);
  // };



  return (
    <div style={{ marginBottom: 50}}>
      <Form.Group>
        <Form.Label className="title">
          {isEdit ? "Edit Master Product" : "Add Master Product"}
        </Form.Label>
        <div className="float-right">
          <Button onClick={onSubmitForm} style={{ width: 135, height: 46 }} variant="info">
            Save
          </Button>
        </div>
      </Form.Group>

      <Grid textAlign="center" style={{ height: "100vh"}}>
        <Row>
          <Col md={8}>
            <Card>
              <Card.Body>
                <Form.Group as={Row}>
                  <Form.Label
                      style={{
                        fontWeight: 610,
                        fontSize: 17,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      column
                      sm={4}
                  >
                    Master Product
                  </Form.Label>
                </Form.Group>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Product Type
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control as="select" onChange={onProduxtTypeChange} value={productType}>
                      <option value={0}>Ready</option>
                      <option value={1}>Pre Order</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Product Name
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                        type="text"
                        placeholder="Name"
                        required
                        onChange={onProductNameChange}
                        value={name}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    SKU
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                        type="text"
                        placeholder="SKU"
                        required
                        onChange={onProductSKUChange}
                        value={sku}
                    />
                  </Col>
                </Form.Group>
                <hr/>
                {/* <hr />
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Warehouse
                  </Form.Label>
                  <Col sm={0}> */}
                {/* {warehouseState.map((item, index) => {
                      if (warehouseState?.some((i) => i.id === item.id)) {
                        return (
                          <Form.Check
                            label={item.name}
                            name="group1"
                            type="checkbox"
                            value={item.id}
                            onClick={() => onCheckCategory(item.id)}
                            defaultChecked
                          />
                        );
                      } else {
                        return (
                          <Form.Check
                            label={item.name}
                            name="group1"
                            type="checkbox"
                            value={item.id}
                            onClick={() => onCheckCategory(item.id)}
                          />
                        );
                      }
                    })} */}
                {/* <Form.Control as="select" onChange={onWarehouseIdChange} value={warehouseId}>
                      <option>select</option>
                      {warehouseState.map((Item) => {
                        return <option value={Item.id}>{Item.name}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group> */}
                <hr/>
                {/* <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Description
                  </Form.Label>
                  <Col sm={8}>
                    <EditorToolbar />
                    <ReactQuill
                      theme="snow"
                      value={visualProduct}
                      onChange={(value) => setVisualProduct(value)}
                      modules={modules}
                      formats={formats}
                    />
                  </Col>
                </Form.Group> */}

                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Description
                  </Form.Label>
                  <Col sm={8}>
                    <MDEditor value={visualProduct} onChange={onContentChange} preview='edit'/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Description Reseller
                  </Form.Label>
                  <Col sm={8}>
                    <MDEditor value={visualProductReseller} onChange={onVisualResellerChange} preview='edit'/>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Rating
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                        type="number"
                        placeholder="Rating"
                        required
                        onChange={onProductRatingChange}
                        value={rating}
                        min={0}
                        max={5}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4} >
                    Product Highlight
                  </Form.Label>
                  <Col sm={8} style={{ paddingTop: 7 }}>
                    <Form.Check
                        id={"highlight"}
                        name="group1"
                        type="checkbox"
                        checked={isHighlight}
                        onChange={(e) => {
                          setIsHighlight(e.target.checked);
                        }}
                    />
                  </Col>
                </Form.Group>
                {/* <hr /> */}
                {/* <Form.Group as={Row} className="input-padding">
                  <Form.Label column sm={4}>
                    Product Page Setting
                  </Form.Label>
                  <Col sm={8}>
                    <section>
                      <p style={{ fontWeight: 600 }}>Enable Sizing Guide</p>
                      <Form.Label className="card-subtitle">
                        Enabling the sizing guide will add a link to the product page that will open
                        the below content in a lightbox.
                      </Form.Label>

                      <div style={{ paddingTop: 10 }}>
                        <span style={{ paddingRight: 20 }}>Sizing Guide</span>
                        <Switch
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          onChange={onSwitchSizingGuideChange}
                        />
                      </div>
                    </section>
                    <hr />
                    <section>
                      <p style={{ fontWeight: 600, marginBottom: 0 }}>Sizing Guide Text</p>
                      <Form.Label className="card-subtitle">you can override the string</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        required
                        onChange={onSizingGuideTextChange}
                        value={sizingGuideText}
                      />
                    </section>
                  </Col>
                </Form.Group>
                <hr /> */}
              </Card.Body>
            </Card>

            <br />

            <Card>
              <Card.Body>
                <section>
                  <Form.Group as={Row} className="input-padding">
                    <Form.Label column sm={2}>
                      Product Data
                    </Form.Label>
                    {/* <Col sm={5}>
                      <Form.Control
                        as="select"
                        onChange={onProductDataTypeChange}
                        value={productDataType}
                      > */}
                        {/* <option value={0}>Simple Product</option>
                        <option value={1}>Grouped Product</option>
                        <option value={2}>External / Affiliate Product</option> */}
                        {/* <option value={3}>Variable Product</option>
                      </Form.Control>
                    </Col> */}
                    {/* <Row style={{ paddingTop: 7 }}>
                      <Col sm={5}>
                        <Form.Check
                          label="virtual"
                          name="group1"
                          type="checkbox"
                          defaultChecked
                          onChange={(e) => {
                            setIsVirtualSimpleProduct(e.target.checked);
                          }}
                        />
                      </Col>
                      <Col sm={2}>
                        <Form.Check
                          label="Downloadable"
                          name="group1"
                          type="checkbox"
                          defaultChecked
                          onChange={(e) => {
                            setIsDownloadableSimpleProduct(e.target.checked);
                          }}
                        />
                      </Col>
                    </Row> */}
                  </Form.Group>
                </section>

                <hr />

                {/* {productDataType === 0 && (
                  <Tabs
                    style={{
                      backgroundColor: "white",
                      border: "0px solid rgba(0,0,0,.125)",
                      color: "black",
                      // borderRadius: ".25rem",
                      margin: 0,
                    }}
                    variant="pills"
                    defaultActiveKey="general"
                  >
                    <Tab eventKey="general" title="General">
                      <GeneralProduct productType={productDataType} data={data} />
                    </Tab>
                    <Tab eventKey="inventory" title="Inventory">
                      <InventoryProduct productType={productDataType} data={data} />
                    </Tab>
                    <Tab eventKey="shipping" title="Shipping">
                      <ShippingProduct productType={productDataType} data={data} />
                    </Tab>
                    <Tab eventKey="linked_product" title="Linked Products">
                      <LinkProduct isEdit={isEdit} />
                    </Tab>
                    <Tab eventKey="attribute" title="Attribute">
                      <AttributeSimpleProductForm data={data} isEdit={isEdit} />
                    </Tab>
                    <Tab eventKey="advanced" title="Advanced">
                      <AdvanceProduct productType={productDataType} data={data} />
                    </Tab>
                  </Tabs>
                )} */}

                {/* {productDataType === 1 && (
                  <Tabs
                    style={{
                      backgroundColor: "white",
                      border: "0px solid rgba(0,0,0,.125)",
                      color: "black",
                      // borderRadius: ".25rem",
                      margin: 0,
                    }}
                    variant="pills"
                    defaultActiveKey="inventory"
                  >
                    <Tab eventKey="inventory" title="Inventory">
                      <InventoryProduct productType={productDataType} data={data} />
                    </Tab>
                    <Tab eventKey="linked_product" title="Linked Products">
                      <LinkProduct isEdit={isEdit} />
                    </Tab>
                    <Tab eventKey="attribute" title="Attribute">
                      <AttributeSimpleProductForm data={data} isEdit={isEdit} />
                    </Tab>
                    <Tab eventKey="advanced" title="Advanced">
                      <AdvanceProduct productType={productDataType} data={data} />
                    </Tab>
                  </Tabs>
                )}

                {productDataType === 2 && (
                  <Tabs
                    style={{
                      backgroundColor: "white",
                      border: "0px solid rgba(0,0,0,.125)",
                      color: "black",
                      // borderRadius: ".25rem",
                      margin: 0,
                    }}
                    variant="pills"
                    defaultActiveKey="general"
                  >
                    <Tab eventKey="general" title="General">
                      <GeneralProduct productType={productDataType} data={data} />
                    </Tab>
                    <Tab eventKey="inventory" title="Inventory">
                      <InventoryProduct productType={productDataType} data={data} />
                    </Tab>
                    <Tab eventKey="linked_product" title="Linked Products">
                      <LinkProduct isEdit={isEdit} />
                    </Tab>
                    <Tab eventKey="attribute" title="Attribute">
                      <AttributeSimpleProductForm data={data} isEdit={isEdit} />
                    </Tab>
                    <Tab eventKey="advanced" title="Advanced">
                      <AdvanceProduct data={data} productType={productDataType} />
                    </Tab>
                  </Tabs>
                )} */}

                {/* {productDataType === 3 && ( */}

                  <Tabs
                    style={{
                      backgroundColor: "white",
                      border: "0px solid rgba(0,0,0,.125)",
                      color: "black",
                      // borderRadius: ".25rem",
                      margin: 0,
                    }}
                    variant="pills"
                    defaultActiveKey="inventory"
                  >
                    <Tab eventKey="inventory" title="Inventory">
                      <InventoryProduct productType={productDataType} data={data} />
                    </Tab>
                    <Tab eventKey="shipping" title="Shipping">
                      <ShippingProduct productType={productDataType} data={data} />
                    </Tab>
                    <Tab eventKey="linked_product" title="Linked Products">
                      <LinkProduct isEdit={isEdit} productId={data.id} />
                    </Tab>
                    <Tab eventKey="attribute" title="Attribute">
                      <AttributeVariableProductForm data={data} isEdit={isEdit}/>
                    </Tab>
                    <Tab eventKey="variations" title="Variations">
                      <VariationVariableProductForm
                        inventorySKU={sku}
                        isEdit={isEdit}
                        data={data}
                      />
                    </Tab>
                    {/* <Tab eventKey="advanced" title="Advanced">
                      <AdvanceProduct productType={productDataType} data={data} />
                    </Tab> */}
                  </Tabs>


                {/* )} */}

                <hr />
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <PublishForm isEdit={isEdit} id={id}/>
            <br />
            <ProductionCategoriesForm data={data} isEdit={isEdit} />
            <br />
            <ProductMembership data={data} isEdit={isEdit} />
            <br />
            <ProductionTagsForm data={data} isEdit={isEdit} />
            <br />
            <ProductImageGallery isEdit={isEdit} />
          </Col>
        </Row>
      </Grid>

      <AlertMessage
        {...alertProps}
        show={showAlert}
        onAlertCloseAction={onAlertClose}
      ></AlertMessage>

<DeleteMessage
        {...confirmAlertProps}
        show={showConfirmAlert}
        onAlertCloseAction={onConfirmAlertClose}
        onDeleteAction={onConfirm}
      ></DeleteMessage>
    </div>
  );
};

export interface ProductFormProps {
  data: ProductModel;
  isEdit: boolean;
}

export default ProductForm;
