import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {getList, getListTransaction, getSingleItem} from '../event.reducer';
import EventTransactionTable from "../components/EventTransactionTable";
import {useParams} from "react-router-dom";

const EventTablePage = () => {
  const dispatch = useDispatch();
  let {id} = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      dispatch(getListTransaction({
        eventId: id,
      }));
      dispatch(getSingleItem(id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
   <Container className="content-body" fluid>
      <Row>
        <Col md={12}>
          <EventTransactionTable />
        </Col>
      </Row>
    </Container>
  );
};

export default EventTablePage;
