import React, {useEffect} from 'react'
import '../slide.scss'
import {Container} from "react-bootstrap";
import {FiEdit} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getFloatingBannerList} from '../floatingBanner.api';
import {RootState} from '../../../../app/store';
import {FloatingBannerEnum, FloatingBannerModel} from "../model";


export default function FloatingBanner() {
    const dispatch = useDispatch()
    const {floatingBannerList} = useSelector((state: RootState) => state.floatingBanner);

    useEffect(() => {
        dispatch(getFloatingBannerList())
    }, [])

    return (
        <Container className="content-body slide" fluid>
            <div className="page-title mb-4 d-flex align-items-center justify-content-between">
                Floating Banner
            </div>
            <div className="box">
                <div className="con">
                    <table className="table">
                        <thead className="">
                        <tr>
                            <th scope="col">image</th>
                            <th scope="col">title</th>
                            <th scope="col">link</th>
                            <th scope="col">type</th>
                            <th scope="col">
                                <div className="text-center">
                                    action
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                           Array.isArray(floatingBannerList) && floatingBannerList?.map((res: FloatingBannerModel, i: number) => (
                                <tr key={i}>
                                    <td>
                                        <img src={res.image} alt="slide"/>
                                    </td>
                                    <td>{res.title === "" ? "-" : res.title ?? "-"}</td>
                                    <td>{res.link === "" ? "-" : res.link ?? "-"}</td>
                                    <td>{res.type === FloatingBannerEnum.Popup ? "Popup Banner" : "Floating Banner"}</td>
                                    <td className='text-center'>
                                        <div className="action">
                                            <Link to={`/floating-banner/form/${res.id}`}>
                                                <FiEdit className='text-info' style={{zIndex: 2}}/>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>

                </div>
            </div>
        </Container>
    )
}
