import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {endpoint} from '../../../constants'
import {UpdateFloatingBannerRequest} from "./model";

export const getFloatingBannerList = createAsyncThunk('floatingBanner/getList', async () => {
    const response = await axios.get(`${endpoint}/api/FloatingBanner/GetList`)
    return response.data
})

export const getSingleFloatingBanner = createAsyncThunk('floatingBanner/getDetail', async (id: string) => {
    try {
        const response = await axios.get(`${endpoint}/api/FloatingBanner/GetSingleItem`, {
            params: {
                id: id
            }
        })
        // console.log(response.data.data, 'detail')
        return response.data.data
    }
    catch (error: any) {
        return error.response
    }
})

export const uploadImage = createAsyncThunk('upload/imagesConvert', async (body: any) => {
    try {
        return await axios.post(`${endpoint}/web/api/Upload/UploadImage`, body)
    }
    catch (error: any) {
        return error.response
    }
})

export const updateFloatingBanner = createAsyncThunk('floatingBanner/update', async (args: UpdateFloatingBannerRequest) => {
    try {
        const response = await axios.post(`${endpoint}/api/FloatingBanner/Update`, args)
        return response.data
    }
    catch (error: any) {
        return error.response
    }
})