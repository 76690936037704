import React, {ChangeEvent, useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import {Card, Form, Row, Col, Button} from "react-bootstrap";
import {Link, useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import AlertMessage from "../../../components/alertMessage";
import {convertBase64} from "../../../components/Library/Base64";
import {DatePicker} from "antd";
import {CreateEventModel, EventModel} from "../event.model";
import {reset, resetStatus} from "../event.reducer";
import moment from "moment/moment";
import UPLOAD_IMAGE from "../../../assets/vector/upload.png";
import {uploadImage} from "../../setting/recommended/recommendedSlide.api";
import {unwrapResult} from "@reduxjs/toolkit";
import {endpoint} from "../../../constants";
import Swal from "sweetalert2";
import RichTextEditor from "../../../components/rich-text/RichTextEditor";
import NumberFormat from "react-number-format";

const EventForm = ({
                       data = {} as EventModel,
                       isEdit = false,
                       ...props
                   }: EventFormProps) => {
    let {id} = useParams<{ id: string }>();

    const dispatch = useDispatch();

    let createStatus = useSelector((state: RootState) => state.event.status ?? "");

    const {loading} = useSelector((state: RootState) => state.slideShow)

    const userId = useSelector(
        (state: RootState) => state.user.id ?? ""
    )

    const errorMessage = useSelector((state: RootState) => state.event.error ?? "");

    let history = useHistory();
    const [name, setName] = useState("");
    const [_isActive, setIsActive] = useState(true);
    const [validated, setValidated] = useState(false);

    const [content, setContent] = useState<string>("");

    const [point, setPoint] = useState(0);

    const [image, setImage] = useState<string | undefined>(undefined)

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [showAlert, setShowAlert] = useState(false);
    const [alertProps, setAlertProps] = useState({
        variant: "",
        title: "",
        content: "",
    });

    const onAlertClose = () => {
        setShowAlert(false);
        if (createStatus === "success") {
            dispatch(reset());
            dispatch(resetStatus());
            history.replace("/event");
        } else {
            dispatch(resetStatus());
        }
    };

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    useEffect(() => {
        if (id === undefined) {
            dispatch(reset());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (createStatus === "success") {
            setAlertProps({
                variant: "success",
                title: "Berhasil membuat event",
                content: "",
            });
            setShowAlert(true);
        } else if (createStatus === "failed") {
            setAlertProps({
                variant: "failed",
                title: "Gagal",
                content: "Terjadi kesalahan ketika membuat event " + ` ${errorMessage}`,
            });
            setShowAlert(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createStatus, errorMessage]);

    useEffect(() => {
        if (data && Object.keys(data).length !== 0 && data.constructor == Object) {
            setName(data.name);
            setIsActive(data.isActive);
            setContent(data.description);
            setStartDate(new Date(data.startDate));
            setEndDate(new Date(data.endDate));
            setImage(data.image);
            setPoint(data.point);

        } else {
            setName("");
            setIsActive(false);
            setContent("");
            setStartDate(new Date());
            setEndDate(new Date());
            setImage(undefined);
            setPoint(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onStartDateChange = (value: any) => {
        if (value !== null) {
            var date = value._d;
            date.setHours(date.getHours() + 7);
            setStartDate(date);
        }
    };

    const onEndDateChange = (value: any) => {
        if (value !== null) {
            var date = value._d;
            date.setHours(date.getHours() + 7);
            setEndDate(date);
        }
    };

    const handleImage = (e: any) => {
        convertBase64(e.target.files[0], (result: any) => {
            const imageData = result.split(',')
            const base64Final = imageData[1]
            const data = {
                "file_name": e.target.files[0].name,
                "base64url": base64Final,
                "imagepath": "event",
                "location": "event"
            }
            dispatch<any>(uploadImage(data))
                .then(unwrapResult)
                .then((res: any) => {
                    console.log(res)
                    if (res.status === 200) {
                        setImage(endpoint + res.data.imagepath)
                        Swal.fire({
                            icon: 'success',
                            text: 'Image uploaded',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: 'Error uploading image!',
                        })
                    }
                }).catch((err: any) => {
                console.log(err)
                Swal.fire({
                    icon: 'error',
                    text: 'Error uploading image!',
                })
            })
        });
    }

    const onContentChangeHandler = (val: string) => {
        setContent(val);
    };

    return (
        <div>
            <Form.Group>
                <Form.Label className="title">
                    {isEdit ? "Edit Event" : "Add Event"}
                </Form.Label>
            </Form.Group>

            <Grid textAlign="center" style={{height: "100vh"}}>
                <Card>
                    <Card.Body>
                        <div>{isEdit ? "Edit Event" : "Add Event"}</div>
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={(e) => {
                                const form = e.currentTarget;
                                if (!form.checkValidity()) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                } else {
                                    e.preventDefault();

                                    props.onSubmit({
                                        name: name,
                                        description: content,
                                        image: image ?? "",
                                        startDate: moment(startDate).toISOString(),
                                        endDate: moment(endDate).toISOString(),
                                        userId: userId,
                                        point: point,
                                    });
                                }
                                setValidated(true);
                            }}
                        >
                            <hr/>
                            <Row className='forms'>
                                <Col md={3}><p>Image</p></Col>
                                <Col md={5}>
                                    <input type="file" name="" style={{display: 'none'}} id="bgImage"
                                           onChange={handleImage}/>
                                    {
                                        loading ?
                                            'Loading...'
                                            :
                                            <label htmlFor='bgImage' className="uploadfilebox overflow-hidden">
                                                {
                                                    image !== undefined ?
                                                        <img src={image} alt="upload" className='img-fluid'/>
                                                        :
                                                        <>
                                                            <img src={UPLOAD_IMAGE} alt="upload"/>
                                                            Select File
                                                        </>
                                                }
                                            </label>
                                    }
                                </Col>
                            </Row>
                            <hr/>
                            <Form.Group as={Row} className="input-padding">
                                <Form.Label column sm={3}>
                                    Name
                                </Form.Label>
                                <Col sm={5}>
                                    <Form.Control type="text" required onChange={onNameChange} value={name}/>
                                </Col>
                            </Form.Group>
                            <hr/>
                            <Form.Group as={Row} className="input-padding">
                                <Form.Label column sm={3}>
                                    Point
                                </Form.Label>
                                <Col sm={5}>
                                    <NumberFormat
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        className="form-control"
                                        inputMode="numeric"
                                        value={point}
                                        onValueChange={({floatValue}) => {
                                            let val = floatValue ?? 0;
                                            setPoint(parseInt(val.toString()));
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                            <hr/>
                            <Form.Group as={Row} className="input-padding">
                                <Form.Label column sm={3}>
                                    Start Date
                                </Form.Label>
                                <Col sm={5}>
                                    <DatePicker
                                        defaultValue={moment(startDate, "YYYY-MM-DD HH:mm")}
                                        value={moment(startDate, "YYYY-MM-DD HH:mm")}
                                        style={{width: "100%"}}
                                        showTime={{format: "HH:mm"}}
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={onStartDateChange}
                                    />
                                </Col>
                            </Form.Group>
                            <hr/>
                            <Form.Group as={Row} className="input-padding">
                                <Form.Label column sm={3}>
                                    End Date
                                </Form.Label>
                                <Col sm={5}>
                                    <DatePicker
                                        defaultValue={moment(endDate, "YYYY-MM-DD HH:mm")}
                                        value={moment(endDate, "YYYY-MM-DD HH:mm")}
                                        style={{width: "100%"}}
                                        showTime={{format: "HH:mm"}}
                                        format="YYYY-MM-DD HH:mm"
                                        onChange={onEndDateChange}
                                    />
                                </Col>
                            </Form.Group>
                            <hr/>
                            <Form.Group as={Row} className="input-padding">
                                <Form.Label column sm={3}>
                                    Description
                                </Form.Label>
                                <Col sm={9}>
                                    <RichTextEditor value={content} onValueChange={onContentChangeHandler}/>
                                </Col>
                            </Form.Group>
                            {/*{isEdit ? (*/}
                            {/*    <>*/}
                            {/*      <hr/>*/}
                            {/*      <Form.Group as={Row} className="input-padding">*/}
                            {/*        <Form.Label column sm={3}>*/}
                            {/*          Active Status*/}
                            {/*        </Form.Label>*/}
                            {/*        <Col sm={4}>*/}
                            {/*          <DragSwitch*/}
                            {/*              checked={isActive}*/}
                            {/*              onChange={(e) => {*/}
                            {/*                setIsActive(e);*/}
                            {/*              }}*/}
                            {/*          />*/}
                            {/*          <br/>*/}
                            {/*        </Col>*/}
                            {/*      </Form.Group>*/}
                            {/*      <hr/>*/}
                            {/*    </>*/}
                            {/*) : (*/}
                            {/*    <></>*/}
                            {/*)}*/}
                            <div className="btn-footer-group">
                                <Link to={{pathname: `/event`}}>
                                    <Button className="btn-cancel" variant="light">
                                        Cancel
                                    </Button>
                                    {"  "}
                                </Link>
                                <Button variant="info" style={{width: 130, height: 45}} type="submit">
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Grid>

            <AlertMessage
                {...alertProps}
                show={showAlert}
                onAlertCloseAction={onAlertClose}
            ></AlertMessage>
        </div>
    );
};

export interface EventFormProps {
    onSubmit: (form: CreateEventModel) => void;
    data: EventModel;
    isEdit: boolean;
}

export default EventForm;
