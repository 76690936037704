import { StringLiteral } from "typescript";

export interface CustomerModel {
  id: string;
  generatedId: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  gender: number;
  password: string;
  confirmPassword: string;
  address: string;
  countryId: string;
  provinceId: string;
  cityId: string;
  districtId: string;
  postalCode: string;
  type: number;
  isActive: boolean;
  status: number;
  createdDate: string;
  totalTransaction: number;
  passwordLog: string;
  code: string;
  userId?: string;
  wallet: number;
  point: number;
  stockistMaxToken?: number;
  membershipTier: string | null;
  membershipTierId?: string | null;
}

export interface createCustomerModel {
  customerData: CustomerModel;
  checkPasswordData: {
    userId: string;
    password: string;
  };
}

export interface checkPasswordModel {
  userId: string;
  password: string;
}

export interface FilterModel {
  id: string;
  name: string;
  email: string;
  phone: string;
  startDate: string;
  endDate: string;
  partnerType?: number | null;
  skip: number;
  take: number;
  memberhsipTierId: string | null;
}

export interface CustomerSuccessResponse {
  name: string;
  phone: string;
  email: string;
  gender: number;
  password: string;
  confirmPassword: string;
  address: string;
  province: string;
  city: string;
  distric: string;
  postalcode: string;
  id: string;
  createdById: string;
  createdBy: string;
  createdDate: Date;
  modifiedById: string;
  modifiedBy: string;
  modifiedDate: Date;
  isActive: boolean;
  createdByName: string;
}

export interface ProvinceModel {
  id: string;
  no: number;
  name: string;
  countryId: string;
}

export interface CityModel {
  id: string;
  no: number;
  name: string;
  type: string;
  postalCode: string;
  orderRange: string;
  provinceId: string;
}

export interface DistrictModel {
  id: string;
  no: string;
  name: string;
  cityId: string;
}

export interface SalesOrderCountModel {
  unPaidKeep: number;
  paidKeep: number;
  unPaidDropship: number;
  paidDropship: number;
  totalUnPaid: number;
  totalPaid: number;
}

export interface SalesOrderModel {
  id: string;
  salesOrderId: string;
  orderId: string;
  salesOrderDetailId: string;
  variationId: string;
  variationName: string;
  productId: string;
  productType: string;
  productName: string;
  productCode: string;
  quantity: number;
  orderDate: string;
  dueDate: string;
  discount: number;
  price: number;
  subTotal: number;
  grandTotal: number;
  totalTax: number;
  adminFee: number;
  note: string;
  soNumber: string;
  bankName: string;
  noRek: string;
  createdDate: string;
  statusOrder: number;
  paymentStatus: number;
  customerId: string;
  deliveryName: string;
  recipientName: string;
  confirmDate: string;
  partnerName: string;
  partnerEmail: string;
  partnerType?: number;
  orderType: number;
  paymentMethodName: string;
  paymentDate: string;
  countNota: number;
  countExpedition: number;
  countCadangan: number;
  countSuratJalan: number;
  paymentType: number;
  returName: string;
  returNumber: string;
  transactionStatus: number;
}

export interface SalesOrderDetailModel {
  id: string;
  salesOrderId: string;
  variationId: string;
  variationName: string;
  productId: string;
  productName: string;
  quantity: number;
  note: string;
  price: number;
  priceSatuan: number;
  stockQty: number;
  weight: number;
  warehouseName: string;
  inventorySKU: string;
}

export interface ShipmentModel {
  id: string;
  deliveryName: string;
  shippingAddress: string;
  recipientName: string;
  recipientAddress: string;
  recipientPostalCode: string;
  recipientPhone: string;
  recipientProvince: string;
  recipientCity: string;
  recipientSubDistrict: string;
  recipientEmail: string;
  resiNumber: string;
  tarif: number;
  totalWeight: number;
  totalShippingCost: number;
  expeditionId: string;
  tarifType: number;
  deliveryDate: string;
  salesOrderId: string;
  status: number;
  administrationPrice: number;
  note: string;
  isActive: boolean;
}

export enum ThirdPartyTypeEnum {
  None,
  Lazada,
  Tokopedia,
  Shopee,
  Blibli,
  BukaLapak,
  JDid,
  Orami,
  Other,
}

export enum PartnerType {
  Customer,
  Stockist,
  Reseller,
  Admin,
}

export interface CheckOutDetail {
  variationId: string;
  quantity: number;
}

export interface SalesOrderWithDetailModel {
  salesOrder: SOModel;
  shipment: SOShipmentModel;
  salesOrderDetail: SalesOrderDetailModel[];
}

export interface SOModel {
  id: string;
  orderDate: Date;
  paymentMethodId: string;
  statusOrder: number;
  shipmentCost: number;
  discount: number;
  discountPercentage: number;
  discountType: number;
  note: string;
  soNumber: string;
  transactionStatus: number;
  subTotal: number;
  grandTotal: number;
  qtyTotal: number;
  totalTax: number;
  taxPercentage: number;
  taxStatus: number;
  partnerId: string;
  orderType: number;
  adminFee: number;
  thirdPartyName: string;
  shippingDiscount: number;
  paymentType: number;
  membershipDiscount: number;
  contractStockistDiscount: number;
  contractStockistFreeShippingDiscount: number;
  contractDiscount: number;
  warehouseList: string;
  customerEmail: string;
  customerName: string;
  customerPhone: string;
  returName: string;
  adminGudang: string;
  adminQC?: string;
  adminEkspedisi?: string;
  adminPacking?: string;
  barcodeImage?: string;
  cashlessExpedition?: string;
  printNotaDate: string;
  printExpeditionDate: string;
  printCadanganDate: string;
  printSuratJalanDate: string;
  status?:number;
}

export interface SOShipmentModel {
  deliveryName: string;
  shippingAddress: string;
  recipientName: string;
  recipientAddress: string;
  recipientPostalCode: string;
  recipientPhone: string;
  recipientProvinceId: string;
  recipientCityId: string;
  recipientDistrictId: string;
  recipientEmail: string;
  resiNumber: string;
  totalWeight: number;
  shippingCost: number;
  originalShippingCost: number;
  totalShippingCost: number;
  expeditionId: string;
  tarifType: string;
  deliveryDate: Date;
  salesOrderId: string;
  administrationPrice: number;
  note: string;
  service: string;
  cost: number;
  senderName: string;
  expeditionName: string;
  isFree: boolean;
  status: number;
  isPrintA: boolean | null;
  recipientProvinceName: string;
  recipientCityName: string;
  recipientDistrictName: string;
  cashlessAddress: string;
  cashlessExpedition: string;
}

export interface CheckOutAdminRequestModel {
  senderCityId: string | null;
  destinationDistrictId: string;
  salesOrderDetailList: CheckOutDetail[];
}

export interface CheckOutDetail {
  variationId: string;
  quantity: number;
}

export interface ExpeditionServiceCostModel {
  expeditionId: string;
  code: string;
  name: string;
  costs: ServiceModel[];
}

export interface ServiceModel {
  service: string;
  description: string;
  cost: CostModel[];
}

export interface CostModel {
  value: number;
  etd: string;
}

export interface EditShipmentModel {
  salesOrderId: string;
  senderName: string;
  recipientName: string;
  recipientAddress: string;
  recipientPostalCode: string;
  recipientPhone: string;

  cashlessExpedition?: string;

  provinceId?: string;
  cityId?: string;
  districtId?: string;
  expeditionId?: string;
  tarifType?: string;

  resiNumber: string;
  totalShippingCost: number;
}
