import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { Content } from "../../../components/Layout";
import DashboardPage from "../../dashboard/containers/DashboardPage";
import CustomerPage from "../../customer/containers/CustomerPage";
import CustomerOrderDetail from "../../customer/components/CustomerOrderDetail";
import ListOrderDetail from "../../customer/components/ListOrderDetail";
import HistoryChat from "../../historyChat/components/HistoryChat";
import UserPage from "../../user/containers/UserPage";
import CustomerTablePage from "../../customer/containers/CustomerTablePage";
import AttributeTablePage from "../../master/attribute/containers/attributeTablePage";
import AttributePage from "../../master/attribute/containers/attributePage";
import ConfigureTermsCreatePage from "../../master/attribute/containers/configureTermsCreatePage";
import SettingProfilePage from "../../settingProfile/containers/settingProfilePage";
import ConfigureTermsEditPage from "../../master/attribute/containers/configureTermsEditPage";
import SupplierTablePage from "../../master/supplier/containers/SupplierTablePage";
import SupplierPage from "../../master/supplier/containers/SupplierPage";
import WarehouseTablePage from "../../master/warehouse/containers/WarehouseTablePage";
import WarehousePage from "../../master/warehouse/containers/WarehousePage";
import ProvinceTablePage from "../../master/province/containers/provinceTablePage";
import CityTablePage from "../../master/city/containers/cityTablePage";
import DistrictTablePage from "../../master/district/containers/districtTablePage";
import BankTablePage from "../../master/bank/containers/bankTablePage";
import BankPage from "../../master/bank/containers/bankPage";
import UOMTablePage from "../../master/UOM/containers/UOMTablePage";
import UOMPage from "../../master/UOM/containers/UOMPage";
import ProductCategoryPage from "../../master/productCategory/containers/ProductCategoryPage";
import ProductCategoryTablePage from "../../master/productCategory/containers/ProductCategoryTablePage";
import PaymentMethodTablePage from "../../master/paymentMethod/containers/PaymentMethodTablePage";
import RoleTablePage from "../../role/containers/RoleTablePage";
import RolePage from "../../role/containers/RolePage";
import PaymentConfirmationPage from "../../master/paymentConfirmation/containers/paymentConfirmationPage";
import CollectionTagTable from "../../master/collectionTag/components/CollectionTagTable";
import CollectionTagPage from "../../master/collectionTag/containers/CollectionTagPage";
import ProductTablePage from "../../master/product/containers/productTablePage";
import ProductPage from "../../master/product/containers/productPage";
import InternalUseTablePage from "../../internalUse/containers/InternalUseTablePage";
import InternalUsePage from "../../internalUse/containers/InternalUsePage";
import RegisterStockistTablePage from "../../registerStockist/containers/RegisterStockistTablePage";
import RegisterStockistCreate from "../../registerStockist/components/RegisterStockistCreate";
import RegisterResellerTablePage from "../../registerReseller/containers/RegisterResellerTablePage";
import VoucherIndex from "../../master/voucher/containers/voucherIndex";
import VoucherPage from "../../master/voucher/containers/voucherPage";
import InventoryMoveIndex from "../../Inventory/InventoryMove/containers/inventoryMoveIndex";
import InventoryMovePage from "../../Inventory/InventoryMove/containers/inventoryMovePage";
import SettingMembershipPage from "../../settingMembership/containers/SettingMembershipPage";
import InventoryMoveApproval from "../../Inventory/InventoryMove/containers/inventoryMoveApproval";
import SettingMembershipTablePage from "../../settingMembership/containers/SettingMembershipTablePage";
import AdminStockistTablePage from "../../adminStockist/containers/AdminStockistTablePage";
import AdminStockistPage from "../../adminStockist/containers/AdminStockistPage";
import AdminStockistDetailPage from "../../adminStockist/containers/AdminStockistDetailPage";
import StockOpnameTablePage from "../../Inventory/stockOpname/containers/StockOpnameTablePage";
import StockOpnamePage from "../../Inventory/stockOpname/containers/StockOpnamePage";
import InventoryReceiveIndex from "../../Inventory/inventoryReceive/containers/inventoryReceiveIndex";
import InventoryReceiveApproval from "../../Inventory/inventoryReceive/containers/inventoryReceiveApproval";
import Contractindex from "../../setting/contract/containers/contractIndex";
import ContractPage from "../../setting/contract/containers/contractPage";
import PenerimaanInventoryIndex from "../../Inventory/penerimaanInventory/containers/penerimaanInventoryIndex";
import PenerimaanInventoryPage from "../../Inventory/penerimaanInventory/containers/penerimaanInventoryPage";
import SalesOrder from "../../salesOrder/salesOrder/containers/salesOrder";
import RegisterResellerPage from "../../registerReseller/containers/RegisterResellerPage";
import PesananDalamProsesIndex from "../../salesOrder/pesananDalamProses/containers/PesananDalamProsesIndex";
import KeepBelumLunasDetail from "../../salesOrder/pesananDalamProses/keepBelumLunas/components/KeepBelumLunasDetail";
import JatuhTempoDropshipPage from "../../salesOrder/pesananDalamProses/jatuhTempoDropship/containers/JatuhTempoDropshipPage";
import OrderDetailPage from "../../salesOrder/pesananDalamProses/keepBelumLunas/containers/OrderDetailPage";
import KeepPerProductDetail from "../../salesOrder/pesananDalamProses/keepPerProduct/components/KeepPerProductDetail";
import ScanPesananIndex from "../../salesOrder/scanPesanan/containers/scanPesananIndex";
import EditShipment from "../../salesOrder/pesananDalamProses/components/EditShipment";
import RegisterStockistPage from "../../registerStockist/containers/RegisterStockistPage";
import PesananDropshipIndex from "../../salesOrder/pesananDropship/containers/pesananDropshipIndex";
import PesananLunasIndex from "../../salesOrder/pesananLunas/containers/pesananDropshipIndex";
import PesananPiutangIndex from "../../salesOrder/pesananPiutang/containers/pesananPiutangIndex";
import PesananRekapIndex from "../../salesOrder/pesananRekap/containers/pesananRekapIndex";
import PaymentConfirmIndex from "../../konfirmasi&resi/paymentConfirm/containers/PaymentConfirmIndex";
import ReceiptDeliveryTablePage from "../../confirmationReceipt/receiptDelivery/containers/ReceiptDeliveryTablePage";
import CustomerOrderPage from "../../customer/containers/CustomerOrderPage";
import CustomerSODetailPage from "../../customer/containers/CustomerSODetailPage";
import SettingFaqPage from "../../setting/faq/containers/settingFaqPage";
import CustomerEditShipment from "../../customer/components/CustomerEditShipment";
import SettingExpeditionsPage from "../../setting/expedition/containers/SettingExpeditionsPage";
import ExpeditionTablePage from "../../master/expedition/containers/ExpeditionTablePage";
import ExpeditionPage from "../../master/expedition/containers/ExpeditionPage";
import SettingPointRewardPage from "../../setting/pointReward/containers/SettingPointRewardPage";
import SettingImage from "../../setting/image/containers/SettingImage";
import SettingLinkAplication from "../../setting/linkAplikasi/containers/SettingLinkAplication";
import SettingStock from "../../setting/stock/containers/SettingStock";
import InformationStore from "../../setting/informationStore/containers/InformationStore";
import TemplateBroadcast from "../../master/templateBroadcast/containers/TemplateBroadcast";
import HowToOrder from "../../setting/howToOrder/containers/HowToOrder";
import FormHowToOrder from "../../setting/howToOrder/components/FormHowToOrder";
import SlideShow from "../../setting/slideShow/containers/SlideShow";
import FormSlide from "../../setting/slideShow/components/FormSlide";
import ViewSlide from "../../setting/slideShow/components/ViewSlide";
import ViewHowToOrder from "../../setting/howToOrder/components/ViewHowToOrder";
import Membership from "../../membersip/containers/Membership";
import ViewMembership from "../../membersip/components/ViewMembership";
import ChatPage from "../../chatMenu/chat/containers/ChatContainerPage";
import ChatProduct from "../../chatMenu/chatProduct/containers/ChatProductContainerPage";
import FormatNota from "../../setting/formatNota/containers/FormatNota";
import FormFormatNota from "../../setting/formatNota/components/FormFormatNota";
import ViewFormatNota from "../../setting/formatNota/components/ViewFormatNota";
import Aplication from "../../setting/aplication/containers/Aplication";
import OrderDataReport from "../../report/orderDataReport/containers/OrderDataReport";
import DailyMonthlyOrder from "../../report/dailyMonthlyOrderReport/containers/DailyMonthlyOrder";
import LaporanPenjualan from "../../report/laporanPenjualan/containers/LaporanPenjualan";
import LaporanPelunasan from "../../report/laporanPelunasan/containers/LaporanPelunasan";
import LaporanOmset from "../../report/laporanOmset/containers/LaporanOmset";
import InvoicePage from "../../invoice/containers/InvoicePage";
import ReturPage from "../../retur/containers/ReturPage";
import ReturTablePage from "../../retur/containers/ReturTablePage";
import ReturApprovalPage from "../../retur/containers/ReturApprovalPage";
import LaporanPerubahanStock from "../../report/laporanPerubahanStock/containers/LaporanPerubahanStock";
import ComplainsTablePage from "../../complain/containers/ComplainsTablePage";
import ComplainDetailPage from "../../complain/containers/ComplainDetailPage";
import ComplainAddPage from "../../complain/containers/ComplainAddPage";
import AutoCancelReport from "../../report/autoCancelReport/containers/AutoCancelReport";
import AutoCancelMasterReport from "../../report/autoCancelMasterReport/containers/AutoCancelMasterReport";
import AccountReceivableReport from "../../report/accountReceivableReport/containers/AccountReceivableReport";
import AssetReport from "../../report/assetReport/containers/AssetReport";
import UserTablePage from "../../user/containers/UsertablePage";
import LaporanEkspedisiPage from "../../report/laporanEkspedisi/containers/LaporanEkspedisiPage";
import WalletPage from "../../wallet/containers/WalletPage";
import LaporanKartuStockPage from "../../report/laporanKartuStock/containers/LaporanKartuStockPage";
import SettingWarehousePage from "../../setting/warehouse/containers/SettingWarehousePage";
import SettingAddressPage from "../../setting/address/containers/SettingAddressPage";
import PrintListTablePage from "../../printList/containers/PrintListTablePage";
import PrintListDetailPage from "../../printList/containers/PrintListDetailPage";
import PaymentInPage from "../../payment/paymentIn/containers/paymentInPage";
import PaymentInForm from "../../payment/paymentIn/components/paymentInForm";
import PaymentAllocationPage from "../../payment/paymentAllocation/containers/paymentAllocationPage";
import PaymentAllocationDetail from "../../payment/paymentAllocation/components/paymentAllocationDetail";
import PaymentInAllocationHistory from "../../payment/paymentIn/components/paymentInAllocationHistory";
import KeepBarangIndex from "../../salesOrder/keepBarang/containers/KeepBarangIndex";
import AutoCancelKeep from "../../report/autoCancelKeep/containers/AutoCancelKeep";
import ReportStaffPerformancePage from "../../report/laporanPerformaStaff/containers/reportStaffPerformancePage";
import PaymentConfirmEndCustomerPage from "../../konfirmasi&resi/paymentConfirmEndCustomer/containers/PaymentConfirmEndCustomerPage";
import KeepBarangForm from "../../salesOrder/keepBarangForm/KeepBarangForm";
import PesananCashlessIndex from "../../salesOrder/pesananCashless/containers/pesananCashlessIndex";
import PesananNonCashlessIndex from "../../salesOrder/pesananNonCashless/containers/pesananNonCashlessIndex";
import PesananEndCustomerIndex from "../../salesOrder/pesananEndCustomer/containers/pesananEndCustomerIndex";
import TemporaryShipmentIndex from "../../salesOrder/temporaryShipment/containers/TemporaryShipmentIndex";
import ReportTotalPerformancePage from "../../report/laporanTotalPerformaStaff/containers/reportTotalPerformancePage";
import StockistOrderDataReport from "../../report/stockistOrderDataReport/containers/StockistOrderDataReport";
import FooterSettingPage from "../../webSetting/footerSetting/containers/FooterSettingPage";
import WebpageSettingPage from "../../webSetting/pageSetting/containers/WebpageSettingPage";
import PointPage from "../../point/containers/PointPage";
import SubscriberPage from "../../subscriber/containers/SubscriberPage";
import RecommendedSlide from "../../setting/recommended/containers/RecommendedSlide";
import RecommendedSlideForm from "../../setting/recommended/components/RecommendedSlideForm";
import ViewRecommendedSlide from "../../setting/recommended/components/ViewRecommendedSlide";
import MembershipTier from "../../membersipTier/containers/MembershipTier";
import EventTablePage from "../../event/containers/EventTablePage";
import EventPage from "../../event/containers/EventPage";
import EventTransactionTablePage from "../../event/containers/EventTransactionTablePage";
import FloatingBanner from "../../setting/floatingBanner/containers/FloatingBanner";
import FloatingBannerForm from "../../setting/floatingBanner/components/FloatingBannerForm";
import ViewFloatingBanner from "../../setting/floatingBanner/components/ViewFloatingBanner";
// import UserTablePage from "../../user/containers/UserTablePage";

function PageLink() {
  const { path, url } = useRouteMatch();

  return (
    <Content>
      <Segment basic>
        <Switch>
          <Route exact path={path}>
            <DashboardPage />
          </Route>
          <Route path={`${path}setting-profile`}>
            <SettingProfilePage />
          </Route>
          <Route path={`${path}customer`}>
            <CustomerTablePage />
          </Route>
          <Route
            path={`${path}setting-faq/index`}
          >
            <SettingFaqPage />
          </Route>
          <Route
            path={`${path}setting-expedition/index`}
          >
            <SettingExpeditionsPage />
          </Route>
          <Route
            path={`${path}setting-point-reward/index`}
          >
            <SettingPointRewardPage />
          </Route>
          <Route
            path={`${path}setting-image/index`}
          >
            <SettingImage />
          </Route>
          <Route
            path={`${path}setting-link-application/index`}
          >
            <SettingLinkAplication />
          </Route>
          <Route
            path={`${path}setting-stock/index`}
          >
            <SettingStock />
          </Route>
          <Route
              path={`${path}web-setting/footer`}
          >
            <FooterSettingPage />
          </Route>
          <Route
              path={`${path}web-setting/page-setting`}
          >
            <WebpageSettingPage />
          </Route>
          <Route path={`${path}create-customer`}>
            <CustomerPage />
          </Route>

          <Route
            path="/edit-customer/:id"
          >
            <CustomerPage />
          </Route>
          <Route
            path="/customer-order-detail/:id"
          >
            <CustomerOrderPage />
          </Route>
          <Route
            path="/customer-so-detail/:id"
          >
            <CustomerSODetailPage />
          </Route>
          <Route
            path="/customer-edit-shipment/:id"
          >
            <CustomerEditShipment />
          </Route>

          {/* <Route path={`${path}customer-order-detail`}>
            <CustomerOrderPage />
          </Route> */}
          {/* <Route path={`${path}customer-so-detail`}>
            <CustomerSODetailPage />
          </Route> */}

          <Route
            path={`${path}user/index`}
          >
            <UserTablePage />
          </Route>
          <Route
            path={`${path}create-user`}
          >
            <UserPage />
          </Route>
          <Route
            path="/edit-user/:id"
          >
            <UserPage />
          </Route>
          <Route path={`${path}chat-history`}>
            <HistoryChat />
          </Route>
          <Route
            path={`${path}attribute/index`}
          >
            <AttributeTablePage />
          </Route>
          <Route
            path={`${path}attribute/create`}
          >
            <AttributePage />
          </Route>
          <Route
            path="/edit-attribute/:id"
          >
            <AttributePage />
          </Route>
          <Route
            path={`${path}attribute/configure-terms-create/:attributeId`}
          >
            <ConfigureTermsCreatePage />
          </Route>
          <Route
            path={`${path}attribute/configure-terms-edit/:id`}
          >
            <ConfigureTermsEditPage />
          </Route>
          <Route
            path={`${path}supplier/index`}
          >
            <SupplierTablePage />
          </Route>
          <Route
            path={`${path}edit-supplier/:id`}
          >
            <SupplierPage />
          </Route>
          <Route
            path={`${path}supplier/create`}
          >
            <SupplierPage />
          </Route>
          <Route
            path={`${path}warehouse/index`}
          >
            <WarehouseTablePage />
          </Route>
          <Route
            path={`${path}warehouse/create`}
          >
            <WarehousePage />
          </Route>
          <Route
            path={`${path}edit-warehouse/:id`}
          >
            <WarehousePage />
          </Route>
          <Route
            path={`${path}Province/index`}
          >
            <ProvinceTablePage />
          </Route>
          <Route
            path={`${path}city/index`}
          >
            <CityTablePage />
          </Route>
          <Route
            path={`${path}district/index`}
          >
            <DistrictTablePage />
          </Route>
          <Route
            path={`${path}Bank/index`}
          >
            <BankTablePage />
          </Route>
          <Route
            path={`${path}Bank/Create`}
          >
            <BankPage />
          </Route>
          <Route
            path="/edit-bank/:id"
          >
            <BankPage />
          </Route>
          <Route
            path={`${path}UOM/index`}
          >
            <UOMTablePage />
          </Route>
          <Route
            path={`${path}UOM/Create`}
          >
            <UOMPage />
          </Route>
          <Route path="/edit-UOM/:id">
            <UOMPage />
          </Route>
          <Route
            path={`${path}expedition/index`}
          >
            <ExpeditionTablePage />
          </Route>
          <Route
            path={`${path}expedition/Create`}
          >
            <ExpeditionPage />
          </Route>
          <Route
            path="/edit-expedition/:id"
          >
            <ExpeditionPage />
          </Route>

          <Route
            path={`${path}product-category/index`}
          >
            <ProductCategoryTablePage />
          </Route>
          <Route
            path={`${path}product-category/Create`}
          >
            <ProductCategoryPage />
          </Route>
          <Route
            path="/edit-product-category/:id"
          >
            <ProductCategoryPage />
          </Route>
          <Route
            path={`${path}payment-method/index`}
          >
            <PaymentMethodTablePage />
          </Route>
          <Route
            path={`${path}role/index`}
          >
            <RoleTablePage />
          </Route>
          <Route
            path="/edit-role/:id"
          >
            <RolePage />
          </Route>
          <Route
            path={`${path}role/create`}
          >
            <RolePage />
          </Route>
          {/* <Route 
              path={`${path}payment-confirmation/index`}
              component={(props: any) => <PaymentConfirmationTablePage />}
            /> */}
          <Route
            path="/edit-payment-confirmation/:id"
          >
            <PaymentConfirmationPage />
          </Route>
          <Route
            path={`${path}payment-confirmation/index`}
          >
            <PaymentConfirmationPage />
          </Route>

          <Route
            path={`${path}payment-confirm-end-customer/index`}
          >
            <PaymentConfirmEndCustomerPage />
          </Route>

          <Route
            path={`${path}collection-tag/index`}
          >
            <CollectionTagTable />
          </Route>
          <Route
            path={`${path}collection-tag/Create`}
          >
            <CollectionTagPage />
          </Route>
          <Route
            path="/edit-collection-tag/:id"
          >
            <CollectionTagPage />
          </Route>
          <Route
            path={`${path}voucher/index`}
          >
            <VoucherIndex />
          </Route>
          <Route
            path={`${path}voucher/Create`}
          >
            <VoucherPage />
          </Route>
          <Route
            path="/edit-voucher/:id"
          >
            <VoucherPage />
          </Route>
          <Route
            path={`${path}product/index`}
          >
            <ProductTablePage />
          </Route>
          <Route
            path={`${path}product/Create`}
          >
            <ProductPage />
          </Route>
          <Route
            path="/edit-product/:id"
          >
            <ProductPage />
          </Route>
          <Route
            path="/edit-payment-confirmation/:id"
          >
            <PaymentConfirmationPage />
          </Route>
          <Route
            path={`${path}payment-confirmation/index`}
          ><PaymentConfirmationPage />
          </Route>

          <Route
            path={`${path}invoice/index`}
          >
            <InvoicePage />
          </Route>
          <Route
            path={`${path}retur/index`}
          >
            <ReturTablePage />
          </Route>

          <Route path={`${path}retur/edit/:id`}>
            <ReturPage />
          </Route>

          <Route path={`${path}retur/approval/:id`}>
            <ReturApprovalPage />
          </Route>

          <Route path={`${path}create-internal-use`}>
            <InternalUsePage />
          </Route>
          <Route
            path="/edit-internal-use/:id"
          >
            <InternalUsePage />
          </Route>
          <Route
            path={`${path}internal-use/index`}
          >
            <InternalUseTablePage />
          </Route>
          <Route
            path={`${path}register-stockist/index`}
          >
            <RegisterStockistTablePage />
          </Route>
          <Route path={`${path}create-register-stockist`}>
            <RegisterStockistPage />
          </Route>
          <Route
            path="/edit-register-stockist/:id"
          >
            <RegisterStockistPage />
          </Route>
          <Route
            path={`${path}register-reseller/index`}
          >
            <RegisterResellerTablePage />
          </Route>
          <Route path={`${path}create-register-reseller`}>
            <RegisterResellerPage />
          </Route>
          <Route
            path="/edit-register-reseller/:id"
          >
            <RegisterResellerPage />
          </Route>

          {/* CHATS  */}
          <Route path={`/chat`}>
            <ChatPage />
          </Route>
          <Route path={`/chat-product`}>
            <ChatProduct />
          </Route>

          <Route
            path={`${path}penerimaan-inventory/index`}
          >
            <PenerimaanInventoryIndex />
          </Route>
          <Route
            path={`${path}penerimaan-inventory/create`}
          >
            <PenerimaanInventoryPage />
          </Route>
          <Route
            path="/edit-penerimaan-inventory/:id"
          >
            <PenerimaanInventoryPage />
          </Route>
          <Route
            path={`${path}inventory-move/index`}
          >
            <InventoryMoveIndex />
          </Route>
          <Route
            path={`${path}inventory-move/create`}
          >
            <InventoryMovePage />
          </Route>
          <Route
            path="/edit-inventory-move/:id"
          >
            <InventoryMovePage />
          </Route>
          <Route
            path="/approval-inventory-move/:id"
          >
            <InventoryMoveApproval />
          </Route>
          <Route
            path={`${path}inventory-receive/index`}
          >
            <InventoryReceiveIndex />
          </Route>
          <Route
            path={`${path}approval-inventory-receive/:id`}
          >
            <InventoryReceiveApproval />
          </Route>
          <Route
            path={`${path}setting-membership/index`}
          >
            <SettingMembershipTablePage />
          </Route>
          <Route
            path={`${path}setting-address/index`}
          >
            <SettingAddressPage />
          </Route>
          <Route path={`${path}create-setting-membership`}>
            <SettingMembershipPage />
          </Route>

          <Route
            path={`${path}edit-setting-membership/:id`}
          >
            <SettingMembershipPage />
          </Route>

          <Route
            path={`${path}admin-stockist/index`}
          >
            <AdminStockistTablePage />
          </Route>

          <Route path={`${path}create-admin-stockist`}>
            <AdminStockistPage />
          </Route>

          <Route
            path={`${path}edit-admin-stockist/:id`}
          >
            <AdminStockistPage />
          </Route>


          <Route
            path={`${path}detail-admin-stockist/:id`}
          >
            <AdminStockistDetailPage />
          </Route>

          <Route
            path={`${path}complain/index`}
          >
            <ComplainsTablePage />
          </Route>

          <Route
            path={`${path}complain-detail/:id`}
          >
            <ComplainDetailPage />
          </Route>

          <Route
              path={`${path}read-complain-detail/:id`}
          >
            <ComplainDetailPage readOnly />
          </Route>

          <Route
            path={`${path}complain/add`}
          >
            <ComplainAddPage />
          </Route>

          <Route
            path={`${path}stock-opname/index`}
          >
            <StockOpnameTablePage />
          </Route>
          <Route path={`${path}create-stock-opname`}>
            <StockOpnamePage />
          </Route>
          <Route
            path={`${path}edit-stock-opname/:id`}
          >
            <StockOpnamePage />
          </Route>

          {/* SETTINGS by kemal */}
          <Route path={`/information-store`}>
            <InformationStore />
          </Route>
          <Route exact path={`/setting-how-order`}>
            <HowToOrder />
          </Route>
          <Route exact path={`/setting-how-order/form`}>
            <FormHowToOrder />
          </Route>
          <Route exact path={`/setting-how-order/form/:id`}>
            <FormHowToOrder />
          </Route>
          <Route exact path={`/setting-how-order/view/:id`}>
            <ViewHowToOrder />
          </Route>

          <Route exact path={`/setting-slide`}>
            <SlideShow />
          </Route>
          <Route exact path={`/setting-slide/form`}>
            <FormSlide />
          </Route>
          <Route exact path={`/setting-slide/form/:id`}>
            <FormSlide />
          </Route>
          <Route exact path={`/setting-slide/view/:id`}>
            <ViewSlide />
          </Route>

          <Route exact path={`/recommended-slide`}>
            <RecommendedSlide />
          </Route>
          <Route exact path={`/recommended-slide/form`}>
            <RecommendedSlideForm />
          </Route>
          <Route exact path={`/recommended-slide/form/:id`}>
            <RecommendedSlideForm />
          </Route>
          <Route exact path={`/recommended-slide/view/:id`}>
            <ViewRecommendedSlide />
          </Route>

          <Route exact path={`/floating-banner`}>
            <FloatingBanner />
          </Route>
          <Route exact path={`/floating-banner/form/:id`}>
            <FloatingBannerForm />
          </Route>
          <Route exact path={`/floating-banner/view/:id`}>
            <ViewFloatingBanner />
          </Route>

          <Route exact path={`/setting-format-nota`}>
            <FormatNota />
          </Route>
          {/* <Route exact path={`/setting-format-nota/view/:id`}>
            <ViewFormatNota />
          </Route> */}
          <Route exact path={`/setting-format-nota/form/:id`}>
            <FormFormatNota />
          </Route>

          {/* WEB SETTING*/}
          <Route path={`/web-setting/footer`}>
            <FooterSettingPage />
          </Route>

          {/* REPORT  */}
          <Route path={`/setting-aplication`}>
            <Aplication />
          </Route>

          <Route path={`/report-order-data`}>
            <OrderDataReport />
          </Route>

          <Route path={`/report-order-stockist`}>
            <StockistOrderDataReport />
          </Route>

          <Route path={`/report-daily-monthly-order`}>
            <DailyMonthlyOrder />
          </Route>

          <Route path={`/report-penjualan`}>
            <LaporanPenjualan />
          </Route>
          <Route path={`/report-pelunasan`}>
            <LaporanPelunasan />
          </Route>
          <Route path={`/report-omset`}>
            <LaporanOmset />
          </Route>
          <Route path={`/report-perubahan-stock`}>
            <LaporanPerubahanStock />
          </Route>
          <Route path={`/report-autocancel`}>
            <AutoCancelReport />
          </Route>
          <Route path={`/report-autocancel-keep`}>
            <AutoCancelKeep />
          </Route>
          <Route path={`/report-autocancel-master`}>
            <AutoCancelMasterReport />
          </Route>
          <Route path={`/report-account-receivable`}>
            <AccountReceivableReport />
          </Route>
          <Route path={`/report-asset`}>
            <AssetReport />
          </Route>

          <Route path={`/report-expedition`}>
            <LaporanEkspedisiPage />
          </Route>

          <Route path={`/report-kartu-stock`}>
            <LaporanKartuStockPage />
          </Route>

          <Route path={`/report-staff-performance`}>
            <ReportStaffPerformancePage />
          </Route>

          <Route path={`/report-total-staff-performance`}>
            <ReportTotalPerformancePage />
          </Route>

          <Route
            path={`${path}contract-setting/index`}
          >
            <Contractindex />
          </Route>
          <Route
            path={`${path}contract-setting/create`}
          >
            <ContractPage />
          </Route>
          <Route
            path={`${path}contract-setting-edit/:id`}
          >
            <ContractPage />
          </Route>
          {/* sales order */}
          <Route
            path={`${path}sales-order/index`}
          >
            <SalesOrder />
          </Route>

          <Route
            path="/sales-order/:id"
          >
            <SalesOrder />
          </Route>

          <Route
            path={`${path}pesanan-dalam-proses/index`}
          >
            <PesananDalamProsesIndex />
          </Route>

          <Route
              path={`${path}keep-barang/index`}
          >
            <KeepBarangIndex />
          </Route>

          <Route
              path={`${path}keep-barang-form/index`}
          >
            <KeepBarangForm />
          </Route>

          <Route
              path={`${path}temporary-shipment/index`}
          >
            <TemporaryShipmentIndex />
          </Route>

          <Route
            path={`${path}keep-belum-lunas-detail/:id/:customerName`}
          >
            <KeepBelumLunasDetail />
          </Route>

          <Route
            path={`${path}order-detail/:id`}
          >
            <OrderDetailPage />
          </Route>

          <Route
            path={`${path}jatuh-tempo/dropship/edit/:id`}
          >
            <JatuhTempoDropshipPage />
          </Route>

          <Route
            path={`${path}keep-per-product/detail/:id/:productName`}
          >
            <KeepPerProductDetail />
          </Route>

          <Route
            path={`${path}scan-pesanan/index`}
          >
            <ScanPesananIndex />
          </Route>

          <Route
            path={`${path}shipment/edit/:id`}
          >
            <EditShipment />
          </Route>
          {/* <Route path={`${path}admin-stockist/index`}>
            <AdminStockistTablePage />
          </Route> */}

          <Route
            path={`${path}pesanan-dropship/index`}
          >
            <PesananDropshipIndex />
          </Route>

          <Route
            path={`${path}pesanan-rekap/index`}
          >
            <PesananRekapIndex />
          </Route>

          <Route
            path={`${path}pesanan-piutang/index`}
          >
            <PesananPiutangIndex />
          </Route>

          <Route
              path={`${path}pesanan-cashless/index`}
          >
            <PesananCashlessIndex />
          </Route>

          <Route
              path={`${path}pesanan-non-cashless/index`}
          >
            <PesananNonCashlessIndex />
          </Route>

          <Route
            path={`${path}pesanan-lunas/index`}
          >
            <PesananLunasIndex />
          </Route>

          <Route
              path={`${path}pesanan-end-customer/index`}
          >
            <PesananEndCustomerIndex />
          </Route>

          {/* membership  */}
          <Route exact path={`/membership`}>
            <MembershipTier />
          </Route>
          <Route exact path={`/membership/:id`}>
            <ViewMembership />
          </Route>

          {/* event */}
          <Route
              exact
              path={`${path}event`}
          >
            <EventTablePage />
          </Route>
          <Route
              exact
              path={`${path}event/redeem/:id`}
          >
            <EventTransactionTablePage />
          </Route>
          <Route
              exact
              path={`${path}event/form`}
          >
            <EventPage />
          </Route>
          <Route
              exact
              path={`${path}event/form/:id`}
          >
            <EventPage />
          </Route>

          <Route exact path={`/subscriber`}>
            <SubscriberPage />
          </Route>

          {/* //konfirmasi dan resi */}
          <Route exact path={`/template-broadcast`}>
            <TemplateBroadcast />
          </Route>
          <Route exact path={`/template-broadcast/:id`}>
            <TemplateBroadcast />
          </Route>

          <Route
            path={`${path}payment-confirm/index`}
          >
            <PaymentConfirmIndex />
          </Route>

          <Route
            path={`${path}receipt-delivery/index`}
          >
            <ReceiptDeliveryTablePage />
          </Route>

          <Route
            path={`${path}wallet/index`}
          >
            <WalletPage />
          </Route>

          <Route
              path={`${path}point/index`}
          >
            <PointPage />
          </Route>

          <Route
            path={`${path}setting-warehouse/index`}
          >
            <SettingWarehousePage />
          </Route>

          <Route
            path={`${path}print-list/index`}
          >
            <PrintListTablePage />
          </Route>

          <Route
            path="/print-list/:id"
          >
            <PrintListDetailPage />
          </Route>

          <Route
            path={`${path}payment-in/index`}
          >
            <PaymentInPage />
          </Route>

          <Route
            path={`${path}payment-in/create`}
          >
            <PaymentInForm />
          </Route>

          <Route
            path="/payment-in/:id"
          >
            <PaymentInForm />
          </Route>

          <Route
            path="/payment-in-allocation-history/:id"
          >
            <PaymentInAllocationHistory />
          </Route>

          <Route
            path={`${path}payment-allocation/index`}
          >
            <PaymentAllocationPage />
          </Route>

          <Route
            path="/payment-allocation-edit/:id"
          >
            <PaymentAllocationDetail />
          </Route>

          <Route
            path={`${path}payment-allocation/create`}
          >
            <PaymentAllocationDetail />
          </Route>
        </Switch>
      </Segment>
    </Content>
  );
}

export default PageLink;
