import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiErrorResponse } from "../../..";
import Api from "./product.api";
import {
  ProductModel,
  CollectionTagModel,
  ProductCategoryModel,
  CreateProductModel,
  ProductPublishModel,
  AttributeModel,
  UploadImageModel,
  WarehouseModel,
  inputConfigureTermsModel,
  ProductCrossSellModel,
  ProductGroupingModel,
  ProductUpSellModel,
  LinkProductModel,
  ImageGalleryModel,
  variationModel,
  ListOfCustomProductAttribute,
  GroupingModel,
  FilterModel, UpdateIndexModel,
} from "./product.model";

export interface ProductSlice {
  name?: string;
  createdProduct?: ProductModel;
  list?: ProductModel[];
  listProductCategories?: ProductCategoryModel[];
  listCollectionTag?: CollectionTagModel[];
  listGrouping?: GroupingModel[];
  listGroupingPerProduct?: GroupingModel[];
  listGroupingEditData? : string[];
  linkedProductsList : string[];
  listAttribute?: AttributeModel[];
  listWarehouse?: WarehouseModel[];
  listGenerate?: [];
  totalRow? : number;
  single?: ProductModel;
  postData?: ProductModel;
  setProductTempForm?: ProductModel;

  //simple product
  setSimpleProductGeneralTempForm?: ProductModel;
  setSimpleProductInventoryTempForm?: ProductModel;
  setSimpleProductShippingTempForm?: ProductModel;
  setSimpleProductAdvancedTempForm?: ProductModel;
  setLinkProductTempForm?: LinkProductModel;
  setSimpleProductAttributeTempForm?: string[];

  //variable Product
  setVariableProductGeneralTempForm?: ProductModel;
  setVariableProductAttributeTempForm?: string[];
  setVariableProductVariationTempForm?: variationModel[];

  //rightside component
  setPublishTempForm?: ProductPublishModel;
  setProductCategoriesTempForm?: string[];
  setProductMembershipTempForm?: string[];
  setProductTagsTempForm?: string[];
  setImageGalleryTempForm?: string[];
  singleImageGallery?: ImageGalleryModel;

  //utility data
  setVariationData?: inputConfigureTermsModel[];
  setListOfConfigureTermsListData?: string[][];

  isLoading?: boolean;
  isSingleProductLoading?: boolean;
  isIndexLoading?: boolean;
  error?: ApiErrorResponse<any>;
  status?: string;
  isAttributeGenerated? : boolean;
  isVariationDispatched? : boolean;
  uploadImage?: UploadImageModel;
  imagePathList? : string[];
  modifiedDate?: string;

  updateVariantStatus?: string;
  variantError?: ApiErrorResponse<any>;
}

export const createData = createAsyncThunk(
  "createState/create",
  async (Model: CreateProductModel, { rejectWithValue }) => {
    
    try {
      const response = await Api.createData(Model.productModelData);
      console.log(Model)
      if (response.status === true) {
        if (Model.linkCrossSellData.productCrossSell !== "") {
          let productCrossSellData = Model.linkCrossSellData;
          productCrossSellData.productId = response.data.id;
          await Api.createCrossSell(productCrossSellData);
        }

        if (Model.linkCrossSellData.productCrossSell !== "") {
          let productUpSellData = Model.linkUpSellData;
          productUpSellData.productId = response.data.id;
          await Api.createUpSell(productUpSellData);
        }

        if (Model.linkCrossSellData.productCrossSell !== "") {
          let productGroupingData = Model.linkGroupingData;
          productGroupingData.productId = response.data.id;
          await Api.createGrouping(productGroupingData);
        }

     

        console.log(Model.productPublishData);
        console.log(Model.imageGalleryData);

        let productPublishData = Model.productPublishData;
        let productImageGalleryData = Model.imageGalleryData;

        productPublishData.productId = response.data.id;
        productImageGalleryData.productId = response.data.id;

        await Api.createProductPublishData(productPublishData);
        await Api.createImageGallery(productImageGalleryData);

        let variationModel: variationModel[] = [];

        const uploadImage = Model.variationData.map(async (item) => {
          let temp = Object.assign({}, item);
          if (item.image !== "") {
            let imgObj = {
              file_name: "file_name." + item.image.split("/")[1].split(";")[0],
              base64url: item.image.split(",")[1],
              imagepath: "product",
              location: "product",
            };
            const responseUpload = await Api.uploadImage(imgObj);
            if (responseUpload.stat !== "") {
              temp.image = responseUpload.imagepath;
              temp.productId = response.data.id;
            }
          } else {
            temp.productId = response.data.id;
          }
          variationModel.push(temp);
        });


        Promise.all(uploadImage).then(() => {
          console.log(variationModel)
          Api.createVariation(variationModel);
        });
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getList = createAsyncThunk(
  "getListState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListWithPaging = createAsyncThunk(
  "getListWithPagingState/getListWithPaging",
  async (model: FilterModel, { rejectWithValue }) => {
    try {
      return await Api.getProductListWithPaging(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListGrouping = createAsyncThunk(
  "getListGroupingState/getListGrouping",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleItem = createAsyncThunk(
  "SingleItemState/SingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleItem(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateData = createAsyncThunk(
  "StateProduct/update",
  async (Model: CreateProductModel, { rejectWithValue, dispatch }) => {
    try {
      const response = await Api.updateData(Model.productModelData);

      if (response.status === true) {
        console.log("gallery", Model.imageGalleryData);
        if (Model.linkCrossSellData.productCrossSell !== "") {
          let productCrossSellData = Model.linkCrossSellData;
          productCrossSellData.productId = response.data.id;
          await Api.createCrossSell(productCrossSellData);
        }

        if (Model.linkCrossSellData.productCrossSell !== "") {
          let productUpSellData = Model.linkUpSellData;
          productUpSellData.productId = response.data.id;
          await Api.createUpSell(productUpSellData);
        }

        if (Model.linkCrossSellData.productCrossSell !== "") {
          let productGroupingData = Model.linkGroupingData;
          productGroupingData.productId = response.data.id;
          await Api.createGrouping(productGroupingData);
        }

        let productPublishData = Model.productPublishData;
        let productImageGalleryData = Model.imageGalleryData;

        productPublishData.productId = response.data.id;
        productImageGalleryData.productId = response.data.id;

        console.log(productPublishData)

        await Api.createProductPublishData(productPublishData);
        await Api.createImageGallery(productImageGalleryData);

        let variationModel: variationModel[] = [];

        const uploadImage = Model.variationData.map(async (item) => {
          let temp = Object.assign({}, item);
          if (item.image !== "") {


            try {
              if(item.image.split(",")[1] !== undefined &&
            item.image.split(",")[1] !== null &&
            item.image.split(",")[1] !== "" ){

              let imgObj = {
                file_name: "file_name." + item.image.split("/")[1].split(";")[0],
                base64url: item.image.split(",")[1],
                imagepath: "product",
                location: "product",
              };
  
              const responseUpload = await Api.uploadImage(imgObj);
              if (responseUpload.stat !== "") {
                temp.image = responseUpload.imagepath;
                temp.productId = response.data.id;
              }

            }
            } catch (error : any) {
              console.log("ERROR : " + error.toString());
            }

            

          } else {
            temp.productId = response.data.id;
          }
          variationModel.push(temp);
        });

        Promise.all(uploadImage).then(() => {
          // Api.updateVariation(variationModel);
          dispatch(updateVariant(variationModel));
        });
      }
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateVariant = createAsyncThunk(
    "StateProduct/updateVariant",
    async (variants: variationModel[], { rejectWithValue }) => {
      try {
        return await Api.updateVariation(variants);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

export const getProductGroupingPerProduct = createAsyncThunk(
  "getProductGroupingPerProductState/getProductGroupingPerProduct",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getProductGroupingPerProduct(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const deleteData = createAsyncThunk(
  "deleteProductState/deletePRoduct",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.deleteData(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListProductCategories = createAsyncThunk(
  "getListProductCategoriesState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getListProductCategories();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListCollectionTag = createAsyncThunk(
  "getListProductCollectionTagState/getList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getListCollectionTag();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getListAttribute = createAsyncThunk(
  "getListAttributeState/getListAttribute",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getListAttribute();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getWarehouseList = createAsyncThunk(
  "getWarehouseListState/getWarehouseList",
  async (_: string | undefined = undefined, { rejectWithValue }) => {
    try {
      return await Api.getWarehouseList();
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const uploadImage = createAsyncThunk(
  "ProductCategoryCreateState/ProductCategoryCreate",
  async (Model: UploadImageModel, { rejectWithValue }) => {
    try {
      return await Api.uploadImage(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const uploadMultipleImage = createAsyncThunk(
  "ProductUploadMultipleImageState/ProductUploadMultipleImage",
  async (Model: UploadImageModel[], { rejectWithValue }) => {
    try {
      return await Api.uploadMultipleImage(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createCrossSellData = createAsyncThunk(
  "createCrossSellDataState/create",
  async (Model: ProductCrossSellModel, { rejectWithValue }) => {
    try {
      return await Api.createCrossSell(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createUpSellData = createAsyncThunk(
  "createUpSellDataState/create",
  async (Model: ProductUpSellModel, { rejectWithValue }) => {
    try {
      return await Api.createUpSell(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createGroupingData = createAsyncThunk(
  "createGroupingDataState/create",
  async (Model: ProductGroupingModel, { rejectWithValue }) => {
    try {
      return await Api.createGrouping(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const getSingleImageGalleryData = createAsyncThunk(
  "SingleImageGalleryItemState/SingleItem",
  async (id: string, { rejectWithValue }) => {
    try {
      return await Api.getSingleImageGallery(id);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

//generate
export const generateForVariation = createAsyncThunk(
  "productState/generateForVariation",
  async (Model: ListOfCustomProductAttribute[], { rejectWithValue }) => {
    try {
      return await Api.generateCustomProductAttribute(Model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);


export const importDataProduct = createAsyncThunk(
  "productState/importDataProduct",
  async (file: any[], { rejectWithValue }) => {
    try {
      return await Api.importDataProduct(file);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const createSingleVariant = createAsyncThunk(
  "productState/createSingleVariant",
  async (model : variationModel, { rejectWithValue }) => {
    try {
      return await Api.createSingleVariation(model);
    } catch (e) {
      return rejectWithValue(e as ApiErrorResponse<any>);
    }
  }
);

export const updateIndex = createAsyncThunk(
    "productState/updateIndex",
    async (args: UpdateIndexModel, { rejectWithValue }) => {
      try {
        return await Api.updateIndex(args);
      } catch (e) {
        return rejectWithValue(e as ApiErrorResponse<any>);
      }
    }
);

const ProductSlice = createSlice({
  name: "ProductState",
  initialState: {} as ProductSlice,
  reducers: {
    setFilter: (state, action) => {
      state.list = action.payload;
    },
    resetSingleProduct: (state) => {
      state.single = {} as ProductModel;
      state.listGroupingPerProduct = [];
      state.listGenerate = [];
      state.linkedProductsList = [];
      state.singleImageGallery = {} as ImageGalleryModel;
    },
    resetStatus: (state) => {
      state.status = "";
      state.listGroupingPerProduct = [] ;
      state.isVariationDispatched = false;
      state.isAttributeGenerated = false ;
      state.setVariableProductVariationTempForm = [] as variationModel[];
      state.updateVariantStatus = "";
      state.variantError = {} as ApiErrorResponse<any>;
    },
    resetIndexStatus: (state) => {
      state.isIndexLoading = false;
      state.status = "";
      state.error = {} as ApiErrorResponse<any>;
    },
    resetImageGallery: (state)=>{
      state.imagePathList = [];
      state.uploadImage = {} as UploadImageModel;
      state.singleImageGallery = {} as ImageGalleryModel;
    },
    setProductTempForm: (state, action) => {
      state.setProductTempForm = action.payload;
    },

    //simple product
    setSimpleProductGeneralTempForm: (state, action) => {
      state.setSimpleProductGeneralTempForm = action.payload;
    },
    setSimpleProductInventoryTempForm: (state, action) => {
      state.setSimpleProductInventoryTempForm = action.payload;
    },
    setSimpleProductShippingTempForm: (state, action) => {
      state.setSimpleProductShippingTempForm = action.payload;
    },
    setSimpleProductAdvancedTempForm: (state, action) => {
      state.setSimpleProductAdvancedTempForm = action.payload;
    },
    setSimpleProductAttributeTempForm: (state, action) => {
      state.setSimpleProductAttributeTempForm = action.payload;
    },
    setLinkProductTempForm: (state, action) => {
      state.setLinkProductTempForm = action.payload;
    },

    //variable Product
    setVariableProductAttributeTempForm: (state, action) => {
      state.setVariableProductAttributeTempForm = action.payload;
    },
    setVariableProductVariationTempForm: (state, action) => {
      state.setVariableProductVariationTempForm = action.payload;
      state.isVariationDispatched = true;
    },

    setIsVariationDispatch: (state, action) => {
      state.isVariationDispatched = action.payload;
    },

    //Linked Product
    setProductGroupingTempForm: (state, action) => {
      state.listGroupingEditData = action.payload;
    },

    //rightside component
    setPublishTempForm: (state, action) => {
      state.setPublishTempForm = action.payload;
    },
    setProductCategoriesTempForm: (state, action) => {
      state.setProductCategoriesTempForm = action.payload;
    },
    setProductMembershipTempForm: (state, action) => {
      state.setProductMembershipTempForm = action.payload;
    },
    setProductTagsTempForm: (state, action) => {
      state.setProductTagsTempForm = action.payload;
    },
    setImageGalleryTempForm: (state, action) => {
      state.setImageGalleryTempForm = action.payload;
    },

    //utility data
    setVariationData: (state, action) => {
      state.setVariationData = action.payload;
    },
    setListOfConfigureTermsListData: (state, action) => {
      state.setListOfConfigureTermsListData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createData.pending, (state) => {
      state.isLoading = true;
      state.status = "";
    });
    builder.addCase(createData.fulfilled, (state, { payload }) => {
      state.createdProduct = payload;
      state.isLoading = false;
      state.status = "success";
    });
    builder.addCase(createData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.isSingleProductLoading = false;
      state.status = "failed";
    });
    builder.addCase(getSingleItem.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
      state.isSingleProductLoading = true;
    });
    builder.addCase(getSingleItem.fulfilled, (state, { payload }) => {
      state.single = payload;
      state.linkedProductsList = payload.productGroupingList;
      state.isLoading = false;
      state.isSingleProductLoading = false;
    });
    builder.addCase(getSingleItem.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getList.fulfilled, (state, { payload }) => {
      state.list = payload;
      state.isLoading = false;
    });
    builder.addCase(getList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListWithPaging.fulfilled, (state, { payload }) => {
      state.list = payload.list;
      state.totalRow = payload.total;
      state.isLoading = false;
    });
    builder.addCase(getListWithPaging.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(getListGrouping.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListGrouping.fulfilled, (state, { payload }) => {
      state.listGrouping = payload;
      state.isLoading = false;
    });
    builder.addCase(getListGrouping.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    builder.addCase(updateData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.modifiedDate = new Date().toISOString();
      state.status = "success";
    });
    builder.addCase(updateData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.status = "failed";
      state.error = payload as ApiErrorResponse<any>;
    });
    builder.addCase(updateVariant.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateVariant.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.updateVariantStatus = "success";
    });
    builder.addCase(updateVariant.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.updateVariantStatus = "failed";
      state.variantError = payload as ApiErrorResponse<any>;
    });
    builder.addCase(deleteData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-delete-product"
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(deleteData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
      state.status = "failed-delete-product"
    });
    builder.addCase(getProductGroupingPerProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getProductGroupingPerProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.listGroupingPerProduct = payload;
      state.modifiedDate = new Date().toISOString();
    });
    builder.addCase(getProductGroupingPerProduct.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload as ApiErrorResponse<any>;
    });

    builder.addCase(getListProductCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListProductCategories.fulfilled, (state, { payload }) => {
      state.listProductCategories = payload;
      state.isLoading = false;
    });
    builder.addCase(getListProductCategories.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListCollectionTag.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListCollectionTag.fulfilled, (state, { payload }) => {
      state.listCollectionTag = payload;
      state.isLoading = false;
    });
    builder.addCase(getListCollectionTag.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getListAttribute.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getListAttribute.fulfilled, (state, { payload }) => {
      state.listAttribute = payload;
      state.isLoading = false;
    });
    builder.addCase(getListAttribute.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getWarehouseList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWarehouseList.fulfilled, (state, { payload }) => {
      state.listWarehouse = payload;
      state.isLoading = false;
    });
    builder.addCase(getWarehouseList.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(uploadImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadImage.fulfilled, (state, { payload }) => {
      state.uploadImage = payload;
      state.isLoading = false;
    });
    builder.addCase(uploadImage.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(uploadMultipleImage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadMultipleImage.fulfilled, (state, { payload }) => {
      state.imagePathList = payload;
      state.isLoading = false;
    });
    builder.addCase(uploadMultipleImage.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(createCrossSellData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createCrossSellData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(createCrossSellData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(createUpSellData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUpSellData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(createUpSellData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(createGroupingData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createGroupingData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(createGroupingData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });

    builder.addCase(getSingleImageGalleryData.pending, (state) => {
      state.single = undefined;
      state.isLoading = true;
    });
    builder.addCase(getSingleImageGalleryData.fulfilled, (state, { payload }) => {
      state.singleImageGallery = payload;
      state.isLoading = false;
    });
    builder.addCase(getSingleImageGalleryData.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    //generate
    builder.addCase(generateForVariation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(generateForVariation.fulfilled, (state, { payload }) => {
      state.listGenerate = payload;
      state.isLoading = false;
      state.isAttributeGenerated = true;
    });
    builder.addCase(generateForVariation.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
    });
    
    builder.addCase(importDataProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(importDataProduct.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-import-data"
      state.isAttributeGenerated = true;
    });
    builder.addCase(importDataProduct.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed-import-data"
    });

    builder.addCase(createSingleVariant.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSingleVariant.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.status = "success-create-single-variant"
    });
    builder.addCase(createSingleVariant.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isLoading = false;
      state.status = "failed-create-single-variant"
    });

    builder.addCase(updateIndex.pending, (state) => {
      state.isIndexLoading = true;
    });
    builder.addCase(updateIndex.fulfilled, (state, { payload }) => {
      state.isIndexLoading = false;
      state.status = "success-update-index"
    });
    builder.addCase(updateIndex.rejected, (state, { payload }) => {
      state.error = payload as ApiErrorResponse<any>;
      state.isIndexLoading = false;
      state.status = "failed-update-index"
    });
  },
});

export const {
  setFilter,
  resetSingleProduct,
  resetStatus,
  resetIndexStatus,
  resetImageGallery,
  setSimpleProductGeneralTempForm,
  setSimpleProductInventoryTempForm,
  setSimpleProductShippingTempForm,
  setSimpleProductAdvancedTempForm,
  setLinkProductTempForm,
  setProductGroupingTempForm,
  setSimpleProductAttributeTempForm,
  setVariableProductAttributeTempForm,
  setVariationData,
  setListOfConfigureTermsListData,
  setPublishTempForm,
  setImageGalleryTempForm,
  setProductCategoriesTempForm,
  setProductMembershipTempForm,
  setProductTagsTempForm,
  setVariableProductVariationTempForm,
  setIsVariationDispatch,
} = ProductSlice.actions;
export default ProductSlice.reducer;
