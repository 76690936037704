import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Container } from "react-bootstrap";
import uploadImage from '../../../../assets/vector/upload.png'

import { FiChevronLeft } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { getSlideShowDetail } from '../slideShow.api';
import { unwrapResult } from '@reduxjs/toolkit';

export default function ViewFloatingBanner() {
    const dispatch = useDispatch()
    const { id } = useParams<any>();

    const [values, setValues] = useState<any>({
        "title": "",
        "link": "",
        "image": ""
    })    

    // useEffect(() => {
    //     dispatch<any>(getSlideShowDetail(id))
    //     .then(unwrapResult)
    //     .then((res:any)=>{
    //         console.log(res)
    //         setValues({
    //             ...values,
    //             ...res
    //         })
    //     })
    // }, [])
    
    
    return (
        <Container className="content-body addHowToOrderForm" fluid>
            <div className="page-title mb-4 title-link">
                <Link to='/floating-banner'>
               <FiChevronLeft /> 
               </Link>
               View Slide Show
            </div>
            <div className="box">
                <div className="titbox">View Floating Banner</div>
                    <Row className='forms'>
                        <Col md={3}><p>Title</p> </Col>
                        <Col md={9}>
                        <input type="text" name="infoStore" placeholder='Title' readOnly value={values.title} />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Image</p> </Col>
                        <Col md={9}>
                            <img className="uploadfilebox" src={values.image} alt="prod" />
                        </Col>
                    </Row>
                    <Row className='forms'>
                        <Col md={3}><p>Link</p> </Col>
                        <Col md={9}>
                        <input type="text" name="infoStore" placeholder='Link' readOnly value={values.link} />
                        </Col>
                    </Row>

            </div>
        </Container>
    )
}
